import React from 'react';
import InfoIcon from '../../assets/svgs/InfoIcon';
import Modal from '../Modal';
import './styles/Info.css';

interface InfoProps {
  isIconDisabled: boolean;
}

function Info(props: InfoProps): React.ReactElement {
  const [isViewingInfo, setIsViewingInfo] = React.useState<boolean>(false);

  const modalBody = (
    <div className="instructions-body">
      <div className="info-item">
        Muldle.app is a daily guess the episode game for fans
        of <i>The X-Files</i> inspired by similar games
        like <a href="https://framed.wtf/" rel="noreferrer" target="_blank">
          Framed
        </a> 
        , <a href="https://guessthe.game/" rel="noreferrer" target="_blank">
          GuessTheGame
        </a>
        , and <a href="https://episode.wtf/" rel="noreferrer" target="_blank">
          Episode
        </a>.
      </div>
      <div className="info-item">
        All image rights belong to 20th Century Studios, Inc. 
        and Chris Carter. No copyright infringement intended.
      </div>
      <div className="info-item">
        This game is and always will be free and is for entertainment 
        purposes only.
      </div>
      <div className="info-item">
        Screenshots sourced from <a href="https://xfilesarchive.com/index.html" rel="noreferrer" target="_blank">xfilesarchive.com</a>
      </div>
    </div>
  );

  return (
    <>
      <InfoIcon
        isIconDisabled={props.isIconDisabled}
        showInfo={() => setIsViewingInfo(!isViewingInfo)}
      />
      {isViewingInfo && (
        <Modal
          modalBody={modalBody}
          modalTitle="ABOUT"
          onCloseModal={setIsViewingInfo}
        />
      )}
    </>
  );
}

export default Info;
