import React from 'react';
import './icons.css';

interface QuestionMarkIconProps {
  isIconDisabled: boolean;
  showInstructions: () => void;
}

function QuestionMarkIcon(props: QuestionMarkIconProps): React.ReactElement { 
  return (
    <span title="How to Play">
      <svg width="24pt" height="24pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" className={props.isIconDisabled ? "icon disabled" : "icon"} onClick={props.showInstructions}>
        <path d="m600.04 90c-281.22 0-510.04 228.82-510.04 510.04 0 281.22 228.82 509.96 510.04 509.96 281.22 0 509.96-228.74 509.96-509.96 0-281.22-228.74-510.04-509.96-510.04zm0 78.469c238.82 0 431.5 192.76 431.5 431.57 0 238.82-192.68 431.5-431.5 431.5-238.81 0-431.57-192.68-431.57-431.5 0-238.81 192.76-431.57 431.57-431.57zm-6.0547 137.7c-81.988 1.7891-155.36 59.59-176.48 142.68v-0.003907c-2.4805 10.059-0.88672 20.688 4.4375 29.574 5.3281 8.8867 13.953 15.309 23.992 17.859 10.059 2.5078 20.703 0.93359 29.605-4.3789 8.9023-5.3125 15.34-13.93 17.906-23.977 14.523-57.137 67.473-91.441 122.45-81.688 54.98 9.7539 94.168 61.293 89.273 120.23s-51.773 102.53-107.51 102.53l-0.003907 0.003906c-1.8203 0.007813-3.6406 0.13672-5.4414 0.39844-0.4375 0.070312-0.87109 0.14844-1.3047 0.23828-1.7344 0.28906-3.4492 0.69922-5.1328 1.2227-0.15625 0.078125-0.30859 0.15625-0.46484 0.23828-7.3945 2.4922-13.879 7.1367-18.621 13.332-0.39453 0.50391-0.77734 1.0156-1.1484 1.5352-0.89844 1.3047-1.7188 2.6602-2.4531 4.0625-0.18359 0.35156-0.35938 0.70703-0.53125 1.0664-0.66797 1.3438-1.2539 2.7266-1.7617 4.1367-0.13672 0.38281-0.26563 0.76562-0.38672 1.1484-0.53906 1.7852-0.94922 3.6016-1.2266 5.4414-0.027344 0.15234-0.054687 0.30469-0.078125 0.45312-0.32422 1.9258-0.5 3.875-0.53125 5.8242v91.188c-0.042969 10.406 4.0508 20.402 11.383 27.789 7.3281 7.3867 17.289 11.559 27.695 11.598 10.461 0.042969 20.5-4.0938 27.898-11.488 7.3945-7.3984 11.531-17.438 11.488-27.898v-56.168c78.598-17.504 139.3-86.164 146.29-170.27 8.1172-97.707-58.293-186.96-153.79-203.91-11.938-2.1172-23.844-3.0117-35.555-2.7578zm2.6055 506.97c-27.148 0.71484-49.809 23.824-49.809 51.113 0 27.719 23.316 50.805 51.035 50.805s50.883-23.086 50.883-50.805-23.16-51.113-50.883-51.113c-0.42969 0-0.79297-0.011719-1.2266 0z" fillRule="evenodd"/>
      </svg>
    </span>
  );
}

export default QuestionMarkIcon;
