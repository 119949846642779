import React from 'react';
import QuotesGame from '../Quotes/QuotesGame';
import '../styles/Quotes.css';

function Quotes() {
  return (
    <div className="">
      <QuotesGame />
    </div>
  );
}

export default Quotes;
