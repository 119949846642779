import React from 'react';
import Header from './Header/Header';
import Classic from './views/Classic';
import HighScore from './views/HighScore';
import Quotes from './views/Quotes';
import './styles/GameContainer.css';

function GameContainer() {
  const [view, setView] = React.useState<string>('quotes');

  return (
    <div className="game-container">
      <Header setView={setView} view={view} />
      {view === 'classic' && <Classic />}
      {view === 'highScore' && <HighScore />}
      {view === 'quotes' && <Quotes />}
    </div>
  );
}

export default GameContainer;
