import React from 'react';
import { answers } from '../data/answers';
import { episodeTitles } from '../data/episodeTitles';
import { getDateString } from '../utils/dates';
import { resetCurrentStreak, updatePlayCount, updateWinCount } from '../utils/local';
import './styles/GuessContainer.css';

interface GuessContainerProps {
  guessNumber: number;
  setActiveScreenshot: Function;
  setGuessNumber: Function;
}

function GuessContainer(props: GuessContainerProps): React.ReactElement {
  const [isShowingList, setIsShowingList] = React.useState<boolean>(false);
  const [episodeOptions, setEpisodeOptions] = React.useState<any>([]);
  const [guessValue, setGuessValue] = React.useState<any>('');

  const today = getDateString();
  const guessesRemaining = 7 - props.guessNumber;

  function onTypeGuess(text: string): void {
    setGuessValue(text);
    const textLowerCase = text.toLowerCase();

    const matchingEpisodes = episodeTitles
      .filter(title => title.toLowerCase().indexOf(textLowerCase) > -1);

    setEpisodeOptions(matchingEpisodes);

    if (matchingEpisodes.length > 0 && !isShowingList) {
      setIsShowingList(true);
    } else if (!text && isShowingList) {
      setIsShowingList(false);
    }
  }

  function onSelectOption(episode: string): void {
    setGuessValue(episode);
    setIsShowingList(false);
  }

  function recordLoss(): void {
    localStorage.setItem('result', 'loss');
    localStorage.setItem('lastDayCompleted', today);

    updatePlayCount();
    resetCurrentStreak();
    props.setGuessNumber(7);
  }

  function skipScreenshot(): void {
    if (props.guessNumber === 1) {
      localStorage.setItem('wrongGuesses', '1');
    } else {
      const wrongGuessCount =
        parseInt(localStorage.getItem('wrongGuesses') || '0', 10);
      const updatedWrongGuessCount = (wrongGuessCount + 1).toString();
      localStorage.setItem('wrongGuesses', updatedWrongGuessCount);
    }

    if(props.guessNumber < 6) {
      const newGuessNumber = props.guessNumber + 1;
      props.setGuessNumber(newGuessNumber);
      props.setActiveScreenshot(newGuessNumber);
    } else {
      recordLoss();
    }
  }

  function checkGuess(): void {
    const answer = answers.filter(ans => ans.date === today)[0];
    const isCorrectGuess =
      guessValue.toLocaleLowerCase() === answer.title.toLocaleLowerCase();

    if (props.guessNumber === 1) {
      localStorage.setItem('wrongGuesses', '0');
    }
  
    if (isCorrectGuess) {
      localStorage.setItem('result', 'win');
      localStorage.setItem('lastDayCompleted', today);

      updatePlayCount();
      updateWinCount();
      props.setGuessNumber(7);
    } else if (!isCorrectGuess && props.guessNumber < 6) {
      const newGuessNumber = props.guessNumber + 1;

      setGuessValue('');

      const wrongGuessCount =
        parseInt(localStorage.getItem('wrongGuesses') || '0', 10);
      const updatedWrongGuessCount = (wrongGuessCount + 1).toString();
      localStorage.setItem('wrongGuesses', updatedWrongGuessCount);

      props.setGuessNumber(newGuessNumber);
      props.setActiveScreenshot(newGuessNumber);
    } else {
      const wrongGuessCount =
        parseInt(localStorage.getItem('wrongGuesses') || '0', 10);
      const updatedWrongGuessCount = (wrongGuessCount + 1).toString();
      localStorage.setItem('wrongGuesses', updatedWrongGuessCount);

      setGuessValue('');
      recordLoss();
    }
  }

  return (
    <>
      <div className="input-container">
        <div className="autocomplete">
            <input
              className="input"
              placeholder="Search for episode title"
              type="text"
              onChange={(e) => onTypeGuess(e.target.value)}
              value={guessValue}
            />
            {isShowingList && (
              <div className="autocomplete-items">
                {episodeOptions.map(
                  (episode: string) => (
                    <div onClick={() => onSelectOption(episode)}>
                      {episode}
                    </div>
                  )
                )}
              </div>
            )}
        </div>
        <span className="submitWrapper">
          <button
            className="button submit"
            disabled={!guessValue.trim()}
            onClick={() => checkGuess()}
          >
            SUBMIT
          </button>
        </span>
        <button
          className="button skip"
          onClick={() => skipScreenshot()}
        >
          SKIP
        </button>
      </div>
      <div className="guesses-remaining">
        {`${guessesRemaining} Guesses Remaining`}
      </div>
    </>
  );
}

export default GuessContainer;
