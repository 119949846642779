import React from 'react';
import { getDateString } from '../../utils/dates';
import { ScreenshotCollection } from '../types';
import './Screenshot.css';

interface ScreenshotProps {
  activeScreenshot: number;
}

// TODO: check if screenshots util functions will work

function importScreenshots(setTodayShots: Function, today: string) {
  return new Promise(() => {
    import(`../../assets/episodes/${today}`)
      .then((screenshots: ScreenshotCollection) => {
        const shots: any = {};

        Object.values(screenshots)
          .forEach((shot: string, i: number) => shots[`shot${i + 1}`] = shot);

        setTodayShots(shots);
      });
  });
}

async function getScreenshotsForToday(setTodayShots: Function, today: string) {
  return await importScreenshots(setTodayShots, today);
}

function Screenshots(props: ScreenshotProps): React.ReactElement {
  const [todayShots, setTodayShots] = React.useState<any>({});
  const today = getDateString();

  React.useEffect(() => {
    getScreenshotsForToday(setTodayShots, today);
  }, []); // eslint-disable-line

  const isLoading = Object.keys(todayShots).length === 0;

  return isLoading ? <div className="loading">Loading...</div> : (
    <div>
      {props.activeScreenshot === 1 && <div className="screenshot">
        <img src={todayShots.shot1} alt="screenshot 1" className="image" />
      </div>}
      {props.activeScreenshot === 2 &&<div className="screenshot">
        <img src={todayShots.shot2} alt="screenshot 2" className="image" />
      </div>}
      {props.activeScreenshot === 3 && <div className="screenshot">
        <img src={todayShots.shot3} alt="screenshot 3" className="image" />
      </div>}
      {props.activeScreenshot === 4 && <div className="screenshot">
        <img src={todayShots.shot4} alt="screenshot 4" className="image" />
      </div>}
      {props.activeScreenshot === 5 && <div className="screenshot">
        <img src={todayShots.shot5} alt="screenshot 5" className="image" />
      </div>}
      {props.activeScreenshot === 6 && <div className="screenshot">
        <img src={todayShots.shot6} alt="screenshot 6" className="image" />
      </div>}
    </div>
  );
}

export default Screenshots;
