import React from 'react';
import { answers } from '../../data/answers';
import { getDateString } from '../../utils/dates';
import './Share.css';

interface ShareButtonProps {
  nullGuessCount: number;
  wrongGuessCount: number;
}

function ShareButton(props: ShareButtonProps): React.ReactElement {
  const [showCopiedText, setShowCopiedText] = React.useState<boolean>(false);
  let formattedResultIcons = '';
  let puzzleNumber = 1;

  const today = getDateString();

  answers.forEach((answer, i) => {
    if (answer.date === today) {
      puzzleNumber = i + 1;
    }
  });

  function toggleCopiedText(): void {
    setShowCopiedText(true);
    setTimeout(() => setShowCopiedText(false), 1000);
  }

  function formatResultIcons(): void {
    const correctGuess = '🟩 ';
    let wrongGuesses = '';
    let nullGuesses = '';

    if (props.wrongGuessCount > 0) {
      for (let i = 1; i <= props.wrongGuessCount; i++) {
        wrongGuesses += '🟥 ';
      }

      formattedResultIcons = `${formattedResultIcons}${wrongGuesses}`;
    }

    if (props.wrongGuessCount < 6) {
      formattedResultIcons = `${formattedResultIcons}${correctGuess}`;
    }

    if (props.nullGuessCount > 0) {
      for (let j = 1; j <= props.nullGuessCount; j++) {
        nullGuesses += '⬛ ';
      }

      formattedResultIcons = `${formattedResultIcons}${nullGuesses}`;
    }
  }

  function copyResults(): void {
    formatResultIcons();
    const resultString =
      `Muldle #${puzzleNumber} \n 👽 ${formattedResultIcons}`;
    navigator.clipboard.writeText(resultString);
    toggleCopiedText();
  }

  const buttonLabel = showCopiedText ? 'COPIED!' : 'SHARE';

  return (
    <div className="share-container">
      <button
        className="share-button"
        onClick={() => copyResults()}
      >
        {buttonLabel}
      </button>
    </div>
  );
}

export default ShareButton;
