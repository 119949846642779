import React from 'react';
import QuotesShareButton from './QuotesShareButton';
import './QuotesResult.css';

interface QuotesResultProps {
  answerTitle: string;
  answerEpisodeNumber: string;
  quoteNumber: number;
}

function QuotesResult(props: QuotesResultProps): React.ReactElement {
  const result = localStorage.getItem('quoteResult');
  const wrongGuessCount =
    parseInt(localStorage.getItem('quoteWrongGuesses') || '0', 10);
  let nullGuessCount = 0;

  let wrongGuesses = [];
  let nullGuesses = [];

  for (let count = 1; count <= wrongGuessCount; count++) {
    wrongGuesses.push(count);
  }

  if (wrongGuessCount < 5) {
    nullGuessCount = 6 - (wrongGuessCount + 1);

    for (let c = 1; c <= nullGuessCount; c++) {
      nullGuesses.push(c);
    }
  }

  return (
    <div className="result-container">
      {result === 'win' && <div className="result-text">YOU GOT IT!</div>}
      {result === 'loss' && <div className="result-text">THE ANSWER:</div>}
      <span className="title">{props.answerTitle}</span>
      <div className="episode-number">{props.answerEpisodeNumber}</div>
      <div className="result-icons">
        {wrongGuesses.map(() => <div className="result-icon incorrect-guess" />)}
        {result === 'win' && <div className="result-icon correct-guess" />}
        {nullGuesses.map(() => <div className="result-icon null-guess" />)}
      </div>
      <QuotesShareButton
        nullGuessCount={nullGuessCount}
        quoteNumber={props.quoteNumber}
        wrongGuessCount={wrongGuessCount}
      />
    </div>
  );
}

export default QuotesResult;
