import React from 'react';
import QuestionMarkIcon from '../../assets/svgs/QuestionMarkIcon';
import Modal from '../Modal';
import './styles/Instructions.css';

interface InstructionsProps {
  isIconDisabled: boolean;
}

function Instructions(props: InstructionsProps): React.ReactElement {
  const [
    isViewingInstructions,
    setIsViewingInstructions
  ] = React.useState<boolean>(false);

  const modalBody = (
    <div className="instructions-body">
      <div className="instruction-item">
        • Guess the episode of <i>The X-Files</i> using the 
        screenshots as clues.
      </div>
      <div className="instruction-item">
        • If your guess is incorrect, a new screenshot will be revealed.
      </div>
      <div className="instruction-item">
        • You have six chances to guess the episode title.
      </div>
      <div className="instruction-item">
        • Press <b>SKIP</b> to skip to the next screenshot. 
        This counts as a guess!
      </div>
      <div className="instruction-item">
        • There will be a new episode to guess each day.
      </div>
    </div>
  );

  return (
    <>
      <QuestionMarkIcon
      isIconDisabled={props.isIconDisabled}
        showInstructions={
          () => setIsViewingInstructions(!isViewingInstructions)
        }
      />
      {isViewingInstructions && (
        <Modal
          modalBody={modalBody}
          modalTitle="HOW TO PLAY"
          onCloseModal={setIsViewingInstructions}
        />
      )}
    </>
  );
}

export default Instructions;
