import React from 'react';
import './icons.css';

interface InfoIconProps {
  isIconDisabled: boolean;
  showInfo: () => void;
}

function InfoIcon(props: InfoIconProps): React.ReactElement {
  return (
    <span title="Info">
      <svg width="24pt" height="24pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" className={props.isIconDisabled ? "icon disabled" : "icon"} onClick={props.showInfo}>
        <g>
        <path d="m589.36 536.05h21.41c11.75 0 21.273 11.75 21.273 21.273v255.82c0 11.75-9.5234 21.273-21.273 21.273h-21.41c-11.75 0-21.273-11.75-21.273-21.273v-255.82c0-11.75 9.5234-21.273 21.273-21.273z"/>
        <path d="m589.36 387h21.41c11.75 0 21.273 11.75 21.273 21.273v42.684c0 11.75-9.5234 21.273-21.273 21.273h-21.41c-11.75 0-21.273-11.75-21.273-21.273v-42.684c0-11.75 9.5234-21.273 21.273-21.273z"/>
        <path d="m600 983.45c101.7 0 199.23-40.398 271.14-112.31 71.91-71.914 112.31-169.45 112.31-271.14s-40.398-199.23-112.31-271.14c-71.914-71.91-169.45-112.31-271.14-112.31s-199.23 40.398-271.14 112.31c-71.91 71.914-112.31 169.45-112.31 271.14s40.398 199.23 112.31 271.14c71.914 71.91 169.45 112.31 271.14 112.31zm0 63.953v0.003906c-118.66 0-232.46-47.141-316.37-131.04-83.902-83.906-131.04-197.71-131.04-316.37s47.141-232.46 131.04-316.37c83.906-83.902 197.71-131.04 316.37-131.04s232.46 47.141 316.37 131.04c83.902 83.906 131.04 197.71 131.04 316.37s-47.141 232.46-131.04 316.37c-83.906 83.902-197.71 131.04-316.37 131.04z"/>
        </g>
      </svg>
    </span>
  );
}

export default InfoIcon;
