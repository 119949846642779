import React from 'react';
import ReactGA from 'react-ga4';
import GameContainer from './components/GameContainer';
import './App.css';


function App() {
  // for testing
  // localStorage.clear();

  ReactGA.initialize('G-KH9JB3DP3R');
  ReactGA.send('pageview');

  if (!localStorage.getItem('playCount')) {
    localStorage.setItem('playCount', '0');
    localStorage.setItem('winCount', '0');
    localStorage.setItem('currentStreak', '0');
    localStorage.setItem('maxStreak', '0');
    localStorage.setItem('lastDayCompleted', '1-1-2022');
    localStorage.setItem('wrongGuesses', '0');
  }

  if(!localStorage.getItem('quotePlayCount')) {
    localStorage.setItem('quotePlayCount', '0');
    localStorage.setItem('quoteWinCount', '0');
    localStorage.setItem('quoteCurrentStreak', '0');
    localStorage.setItem('quoteMaxStreak', '0');
    localStorage.setItem('quoteLastDayCompleted', '1-1-2022');
    localStorage.setItem('quoteWrongGuesses', '0');
  }

  if (!localStorage.getItem('highScoreStats')) {
    const emptyHighScoreStats = Array(11).fill(0);
    localStorage.setItem('highScoreStats', JSON.stringify(emptyHighScoreStats));
  }

  return (
    <div className="app">
      <GameContainer />
    </div>
  );
}

export default App;
