import { ScreenshotCollection } from "../components/types";

export function importScreenshots(setScreenshots: Function, date: string) {
    return new Promise(() => {
        import(`../assets/episodes/${date}`)
        .then((screenshots: ScreenshotCollection) => {
            const shots: any = {};

            Object.values(screenshots)
            .forEach((shot: string, i: number) => shots[`shot${i + 1}`] = shot);

            setScreenshots(shots);
        });
    });
    }

export async function getScreenshotsForDate(setScreenshots: Function, date: string) {
    return await importScreenshots(setScreenshots, date);
}
