import React from 'react';
import CloseIcon from '../assets/svgs/CloseIcon';
import './styles/Modal.css';

interface ModalProps {
  modalBody: React.ReactElement;
  modalTitle: string;
  onCloseModal: Function;
}

function Modal(props: ModalProps): React.ReactElement {
  return (
    <div className="modal">
      <div className="modal-header">
        <span className="modal-title">{props.modalTitle}</span>
        <CloseIcon setIsModalOpen={props.onCloseModal} />
      </div>
      {props.modalBody}
    </div>
  )
}

export default Modal;
