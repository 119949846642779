import React from 'react';
import StatsIcon from '../../assets/svgs/StatsIcon';
import Modal from '../Modal';
import { getStats } from '../../utils/local';
import './styles/Stats.css';

interface StatsProps {
  isIconDisabled: boolean;
  view: string;
}

function Stats(props: StatsProps): React.ReactElement {
  const [isViewingStats, setIsViewingStats] = React.useState<boolean>(false);
  const stats = getStats(props.view);

  const body = (
    <div className="modal-body">
      <div className="row">
        <div className="stat-item top-row-item">
          {stats.playCount}
          <span className="stat-title">
            Played
          </span>
        </div>
        <div className="stat-item top-row-item">
          {stats.winCount}
          <span className="stat-title">
            Won
          </span>
        </div>
      </div>
      <div className="row">
        <div className="stat-item">
          {stats.winPercent}%
          <span className="stat-title">
            Win %
          </span>
        </div>
      </div>
      <div className="row">
        <div className="stat-item">
          {stats.currentStreak}
          <span className="stat-title">
            Current Streak
          </span>
        </div>
        <div className="stat-item">
          {stats.maxStreak}
          <span className="stat-title">
            Max Streak
          </span>
        </div>
      </div>
    </div>
  );

  const title = props.view === 'classic' ? 'CLASSIC STATS' : 'QUOTES STATS';

  return (
    <>
    <StatsIcon
      getStats={() => setIsViewingStats(!isViewingStats)}
      isIconDisabled={props.isIconDisabled}
    />
    {isViewingStats && (
      <Modal
        modalBody={body}
        modalTitle={title}
        onCloseModal={setIsViewingStats}
      />
    )}
    </>
  );
}

export default Stats;
