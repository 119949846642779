import React from 'react';
import { getLeaderboardData, writeLeaderboardData } from '../../utils/firebase';
import { sethsLeaderboardName } from '../../utils/local';
import '../styles/HighScore.css';

interface LeaderboardProps {
    setIsViewingLeaderboard: Function;
}

function Leaderboard(props: LeaderboardProps) {
    const [leaderboardData, setLeaderboardData] = React.useState<any>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const hsLeaderboardName = localStorage.getItem('hsLeaderboardName');

    React.useEffect(() => {
        if (!!hsLeaderboardName) {
            setIsLoading(true);
            getLeaderboardData(setLeaderboardData);
        }
    }, []);

    React.useEffect(() => {
        if (!!leaderboardData) {
            setIsLoading(false);
        }
    }, leaderboardData);

    function onJoinLeaderboard(): void {
        setIsLoading(true);

        //@ts-ignore
        const name = document.getElementById('name-input').value.replace(/[\W_]+/g, '');
        const scores = JSON.parse(localStorage.getItem('highScoreStats') || '[]');
        const perfectScores = scores[10];

        sethsLeaderboardName(name);
        writeLeaderboardData(name, perfectScores);
        getLeaderboardData(setLeaderboardData);
    }

    const joinLeaderboardForm = (
        <div>
            <span className='leaderboard-text'>
                Enter your name for the leaderboard:
            </span>
            <div>
                <input className="leaderboard-input" id="name-input" />
            </div>
            <div>
                <button
                    className="button-global join-button"
                    onClick={() => onJoinLeaderboard()}
                >
                    JOIN
                </button>
            </div>
        </div>
    );

    let userPlace = 0;
    let sortedLeaderboardTop10 = {};
    let totalUserNumber = 0;

    if (!!hsLeaderboardName) {
        const sortedLeaderboardData = Object.entries(leaderboardData || {})
            //@ts-ignore
            .sort(([,a],[,b]) => b - a);

        const sortedLeaderboardDataObject =
            sortedLeaderboardData.reduce((r, [k, v]) => ({ ...r, [k]: v }), {})

        sortedLeaderboardTop10 = [...sortedLeaderboardData].splice(0,10)
            .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

        userPlace = Object.keys(sortedLeaderboardDataObject)
            .indexOf(hsLeaderboardName) + 1;

        totalUserNumber = sortedLeaderboardData.length;
    }

    const leaderboard = !!leaderboardData ? (
        <div className='leaderboard-container'>
            <h1 className='high-score-title'>Leaderboard</h1>
            <table className='leaderboard-table'>
                <thead>
                    <tr className='col-header'>
                        <th>Name</th>
                        <th>Perfect Games</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(sortedLeaderboardTop10)
                        .map((obj: any, index) => {
                            return (
                                <tr key={`row-${index}`}>
                                    <td>{index + 1}. {obj[0]}</td>
                                    <td>{obj[1]}</td>
                                </tr>
                            );
                        }
                    )}
                </tbody>
            </table>
            <div className='leaderboard-text placement'>
                Your placement: {userPlace} out of {totalUserNumber}
            </div>
            <button
                className="leaderboard-back-button button-global"
                onClick={() => props.setIsViewingLeaderboard(false)}
            >
                BACK
            </button>
        </div>
    ) : '';

  return (
    <div className="instructions-container">
        {!hsLeaderboardName && joinLeaderboardForm}
        {isLoading && <div className='leaderboard-text'>Loading leaderboard...</div>}
        {leaderboard}
    </div>
);
}

export default Leaderboard;
