import React from 'react';
import './Screenshot.css';

interface ScreenshotNavProps {
  activeScreenshot: number;
  guessNumber: number;
  hasPlayedToday: boolean;
  setActiveScreenshot: any;
}

function ScreenshotNav(props: ScreenshotNavProps): React.ReactElement {
  return (
    <div className="screenshotNav">
        <button
          className={props.activeScreenshot === 1 ? "active" : ""}
          onClick={() => props.setActiveScreenshot(1)}
        >
          1
        </button>
        {(props.guessNumber > 1 || props.hasPlayedToday) && (
          <button
            className={props.activeScreenshot === 2 ? "active" : ""}
            onClick={() => props.setActiveScreenshot(2)}
          >
            2
          </button>
        )}
        {(props.guessNumber > 2 || props.hasPlayedToday) && (
          <button
            className={props.activeScreenshot === 3 ? "active" : ""}
            onClick={() => props.setActiveScreenshot(3)}
          >
            3
          </button>
        )}
        {(props.guessNumber > 3 || props.hasPlayedToday) && (
          <button
            className={props.activeScreenshot === 4 ? "active" : ""}
            onClick={() => props.setActiveScreenshot(4)}
          >
            4
          </button>
        )}
        {(props.guessNumber > 4 || props.hasPlayedToday) && (
          <button
            className={props.activeScreenshot === 5 ? "active" : ""}
            onClick={() => props.setActiveScreenshot(5)}
          >
            5
          </button>
        )}
        {(props.guessNumber > 5 || props.hasPlayedToday) && (
          <button
            className={props.activeScreenshot === 6 ? "active" : ""}
            onClick={() => props.setActiveScreenshot(6)}
          >
            6
          </button>
        )}
    </div>
  );
}

export default ScreenshotNav;
