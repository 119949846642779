import React from 'react';
import Info from './Info';
import Instructions from './Instructions';
import Logo from './Logo';
import ModeSelect from './ModeSelect';
import Stats from './Stats';
import './styles/Header.css';

interface HeaderProps {
  setView: Function;
  view: string;
}

function Header(props: HeaderProps): React.ReactElement {
  const isIconDisabled = props.view === 'highScore';

  return (
    <>
      <div className="header">
          <Logo />
          <div>
            <Info isIconDisabled={isIconDisabled} />
            <Instructions isIconDisabled={isIconDisabled} />
            <Stats isIconDisabled={isIconDisabled} view={props.view} />
          </div>
      </div>
      <ModeSelect setView={props.setView} view={props.view} />
    </>
  );
}

export default Header;
