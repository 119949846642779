import React from 'react';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

function HighScoreStats(): any {
  Chart.register(ChartDataLabels);

  // @ts-ignore
  const chart = new Chart(document.getElementById('scoreChart'), {
    type: 'bar',
    data: {
      labels: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      datasets: [{
        backgroundColor: [
          '#3b53cd',
          '#3669c9',
          '#367fc0',
          '#3692b7',
          '#35a1ae',
          '#35a59d',
          '#359d82',
          '#34946a',
          '#338c55',
          '#338b53',
          '#328442'
        ],
        barThickness: 40,
        data: JSON.parse(localStorage.getItem('highScoreStats') || '[]'),
      }],
    },
    options: {
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 30,
          bottom: 0,
        },
      },
      plugins: {
        datalabels: {
          align: 'top',
          anchor: 'end',
          font: {
            size: '14px',
          },
        },
        legend: { display: false },
      },
      scales: {
          y: {
              display: false,
          },
          x: {
              gridLines: {
                  display: false,
              },
              title: {
                display: true,
                font: 'Arial, Helvetica, sans-serif',
                text: 'Score',
              },
          },
      },
    },
  });

  return <></>;
}

export default HighScoreStats;
