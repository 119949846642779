import React from 'react';
import { answers } from '../../data/answers';
import { episodeTitles } from '../../data/episodeTitles';
import { writeLeaderboardData } from '../../utils/firebase';
import ScoreIcons from './ScoreIcons';
import '../styles/GuessContainer.css';

const EMPTY_GUESSES = Array(10).fill('empty');

interface HighScoreGuessContainerProps {
    highScoreDate: string;
    round: number;
    score: number;
    setRound: Function;
    setScore: Function;
}

function setFinalScoreInLocalStorage(score: number) {
  const highScoreStats = JSON.parse(localStorage.getItem('highScoreStats') || '[]');
  highScoreStats[score] = highScoreStats[score] + 1;
  localStorage.setItem('highScoreStats', JSON.stringify(highScoreStats));

  const hsLeaderboardName = localStorage.getItem('hsLeaderboardName');

  if (score === 10 && !!hsLeaderboardName) {
    writeLeaderboardData(hsLeaderboardName, highScoreStats[10]);
  }
}

function HighScoreGuessContainer(props: HighScoreGuessContainerProps): React.ReactElement {
  const [isShowingList, setIsShowingList] = React.useState<boolean>(false);
  const [episodeOptions, setEpisodeOptions] = React.useState<any>([]);
  const [guessValue, setGuessValue] = React.useState<any>('');
  const [guesses, setGuesses] = React.useState<any>(EMPTY_GUESSES);

  const isGameOver = props.round > 10;

  React.useEffect(() => {
    if (props.round === 1) {
      setGuesses(EMPTY_GUESSES);
    } else if (isGameOver) {
      setFinalScoreInLocalStorage(props.score);
    }
  }, [props.round]);

  function onTypeGuess(text: string): void {
    setGuessValue(text);
    const textLowerCase = text.toLowerCase();

    const matchingEpisodes = episodeTitles
      .filter(title => title.toLowerCase().indexOf(textLowerCase) > -1);

    setEpisodeOptions(matchingEpisodes);

    if (matchingEpisodes.length > 0 && !isShowingList) {
      setIsShowingList(true);
    } else if (!text && isShowingList) {
      setIsShowingList(false);
    }
  }

  function onSelectOption(episode: string): void {
    setGuessValue(episode);
    setIsShowingList(false);
  }

  function checkHighScoreGuess(): void {
    const correctAnswer =
        answers.filter(ans => ans.date === props.highScoreDate)[0];

    const correctTitle = correctAnswer.title;
    const updatedGuesses = [...guesses];

    if (guessValue === correctTitle) {
      const newScore = props.score + 1;
      updatedGuesses[props.round - 1] = 'correct';

      setGuesses(updatedGuesses);
      setGuessValue('');

      props.setScore(newScore);
      props.setRound(props.round + 1);
    } else {
      updatedGuesses[props.round - 1] = 'incorrect';

      setGuesses(updatedGuesses);
      setGuessValue('');
      props.setRound(props.round + 1);
    }
  }

  function skipHighScoreScreenshot(): void {
    const updatedGuesses = [...guesses];
    updatedGuesses[props.round - 1] = 'incorrect';

    setGuesses(updatedGuesses);
    props.setRound(props.round + 1);
  }

  const scoreTracker = isGameOver
    ? `Final Score: ${props.score}`
    : `Current Score: ${props.score}`;

  const gameOverMessage = (
    <div className="score">
      {props.score < 4 && <div>Better luck next time!</div>}
      {(props.score > 3 && props.score < 7) && <div>Not too shabby!</div>}
      {(props.score > 6 && props.score < 10) && <div>Great job!</div>}
      {props.score === 10 && <div>Wow! A perfect score 😎</div>}
    </div>
  );

  return (
    <>
      {isGameOver ? gameOverMessage : (
        <div className="input-container">
          <div className="autocomplete">
              <input
                className="input"
                placeholder="Search for episode title"
                type="text"
                onChange={(e) => onTypeGuess(e.target.value)}
                value={guessValue}
              />
              {isShowingList && (
                <div className="autocomplete-items">
                  {episodeOptions.map(
                    (episode: string) => (
                      <div onClick={() => onSelectOption(episode)}>
                        {episode}
                      </div>
                    )
                  )}
                </div>
              )}
          </div>
          <span className="submitWrapper">
            <button
              className="button submit"
              disabled={!guessValue.trim()}
              onClick={() => checkHighScoreGuess()}
            >
              SUBMIT
            </button>
          </span>
          <button
            className="button skip"
            onClick={() => skipHighScoreScreenshot()}
          >
            SKIP
          </button>
        </div>
      )}
      <div className="score">
        {scoreTracker}
        <ScoreIcons guesses={guesses} />
      </div>
    </>
  );
}

export default HighScoreGuessContainer;
