import React from 'react';
import ShareButton from './ShareButton';
import { answers } from '../../data/answers';
import { getDateString } from '../../utils/dates';
import './Result.css';

function Result(): React.ReactElement {
  const today = getDateString();
  const answer = answers.filter(ans => ans.date === today)[0];
  const result = localStorage.getItem('result');
  const wrongGuessCount =
    parseInt(localStorage.getItem('wrongGuesses') || '0', 10);
  let nullGuessCount = 0;

  let wrongGuesses = [];
  let nullGuesses = [];

  for (let count = 1; count <= wrongGuessCount; count++) {
    wrongGuesses.push(count);
  }

  if (wrongGuessCount < 5) {
    nullGuessCount = 6 - (wrongGuessCount + 1);

    for (let c = 1; c <= nullGuessCount; c++) {
      nullGuesses.push(c);
    }
  }

  return (
    <div className="result-container">
      {result === 'win' && <div className="result-text">YOU GOT IT!</div>}
      {result === 'loss' && <div className="result-text">THE ANSWER:</div>}
      <span className="title">{answer.title}</span>
      <div className="episode-number">{answer.episodeNumber}</div>
      <div className="result-icons">
        {wrongGuesses.map(() => <div className="result-icon incorrect-guess" />)}
        {result === 'win' && <div className="result-icon correct-guess" />}
        {nullGuesses.map(() => <div className="result-icon null-guess" />)}
      </div>
      <ShareButton
        nullGuessCount={nullGuessCount}
        wrongGuessCount={wrongGuessCount}
      />
    </div>
  );
}

export default Result;
