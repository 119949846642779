import React from 'react';
import Chart from 'chart.js/auto';
import HighScoreGame from '../HighScore/HighScoreGame';
import HighScoreStats from '../HighScore/HighScoreStats';
import Leaderboard from '../HighScore/Leaderboard';
import '../styles/HighScore.css';

function HighScore() {
  const [isPlaying, setIsPlaying] = React.useState<boolean>(false);
  const [isViewingStats, setisViewingStats] = React.useState<boolean>(false);
  const [isViewingLeaderboard, setIsViewingLeaderboard] = React.useState<boolean>(false);
  const [showExpandedNote, setShowExpandedNote] = React.useState<boolean>(false);

  const leaderboardButton = !localStorage.getItem('hsLeaderboardName')
    ? (
      <button
        className="leaderboard-button stats-button button-global"
        onClick={() => setIsViewingLeaderboard(true)}
      >
        JOIN LEADERBOARD
      </button>
    ) : (
      <button
        className="leaderboard-button stats-button button-global"
        onClick={() => setIsViewingLeaderboard(true)}
      >
        LEADERBOARD
      </button>
    );

  const instructions = (
    <div className="instructions-container">
      <h1 className="high-score-title">High Score</h1>
      <div className="instruction">
        Can you name all 10 episodes?
      </div>
      <h2 className="high-score-title subtitle">
        How to Play
      </h2>
      <div className="instruction">
        • In High Score mode, you will see 10 screenshots.
      </div>
      <div className="instruction">
        • Use each screenshot to guess the episode by typing or finding the title below the screenshot.
      </div>
      <div className="instruction">
        • You will get one point for each correct guess.
      </div>
      <div className="instruction">
       • The same episode will <b>not</b> appear twice in one game!
      </div>
      <div className="leaderboard-instruction">
        Join the leaderboard to see how you stack up against other X-Philes 🛸
      </div>
      <button
        className="start-button button-global"
        onClick={() => setIsPlaying(true)}
      >
        PLAY
      </button>
      {!isViewingStats && (
        <button
          className="stats-button button-global"
          onClick={() => setisViewingStats(true)}
        >
          STATS
        </button>
      )}
      {!isViewingStats && leaderboardButton}
    </div>
  );

  let highScoreBody;

  if (isPlaying) {
    highScoreBody = <HighScoreGame setIsPlaying={setIsPlaying} />;
  } else if (isViewingStats) {
    highScoreBody = <HighScoreStats />;
  } else if (isViewingLeaderboard) {
    highScoreBody = <Leaderboard setIsViewingLeaderboard={setIsViewingLeaderboard} />;
  } else {
    highScoreBody = instructions;
  }

  function removeChart() {
    const chart = Chart.getChart('scoreChart');
    chart?.destroy();
    setisViewingStats(false);
  }

  return (
    <div className="game-container">
      {highScoreBody}
      <div className={isViewingStats ? 'instructions-container' : ''}>
        {isViewingStats && (<h1 className="high-score-title">High Score Stats</h1>)}
        <canvas
          id="scoreChart"
          style={{
            display: 'inline',
            margin: '48px 0',
            maxHeight: '350px',
            maxWidth: '500px'
          }}
        ></canvas>
        {isViewingStats && (
          <div>
            <button
              className="back-button button-global"
              onClick={() => removeChart()}
            >
              BACK
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default HighScore;
