const quotes = {
    paperClip: [
        "Lots and lots of files.",
        "That bullet was meant for me.",
        "It was your father's choice and I hated him for it.",
        "The photo was taken at the Strughold Mining Company in West Virginia and that is all I will tell you.",
        "Your cigarette-smoking friend killed my father for that tape and then he killed me.",
        "Our deal with the devil. The U.S. government provided safe haven for certain Nazi war criminals in exchange for their scientific knowledge.",
    ],
    theFieldWhereIDied: [
        "There's no sign of the weapons, they've hid them somewhere.",
        "At times, I almost dream.",
        "Even if I knew for certain, I wouldn't change a day.",
        "He is obsessed with the book of Revelation and will use his knowledge of biblical text to get anything that he wants from his followers.",
        "We are under orders to serve warrants for the arrest of Vernon Warren, also known as Vernon Ephesian!",
        "Mine is the Temple of the Seven Stars",
    ],
    arcadia: [
        "The boy's a weak link and a strong chain can't survive with a weak link.",
        "That's a caduceus. Are you a doctor, Mike?",
        "Shroeder's out there painting our mailbox!",
        "We just spooned up and fell asleep like little baby cats.",
        "It may not sound like anything - a simple basketball hoop - but from there it's just a few short steps to spinning daisy reflectors and a bass boat in the driveway.",
        "But every community has its dark underbelly don't you think?",
    ],
    tempusFugit: [
        "The way all these cracks radiate from a central point, it looks like the door was shaken and blown outward straight off its frame.",
        "I think we all share the same goal here, sir, and that's to find out what caused that plane to crash.",
        "I'm beginning to doubt whether he finished this flight with the rest of the passengers.",
        "Nine minutes that became a lifetime for those passengers and now for their families.",
        "Mulder, you have never remembered my birthday in the four years I've known you.",
        "You're going to keep breathing, Pendrell. Do you hear me?",
    ],
    enAmi: [
        "Is this necessary? I just walked through the wrong door.",
        "Tenants like having an FBI agent in the building. Gives them a sense of security.",
        "I know she can take care of herself. It's just not like her to lie to me.",
        "You're drawn to powerful men but you fear their power.",
        "An 11-year-old boy, diagnosed with lymphatic cancer, cured with a miracle.",
        "I've been a destroyer all my life. Before I die, I'd like to prove that I'm capable of something more.",
    ],
    quagmire: [
        "Those stories must sell a lot of T-shirts.",
        "You can't turn your back on nature or nature will turn her back on you!",
        "They've reduced our attention span to the length of a sound byte so that soon our ability to think will be as extinct as a rana sphenocephala frog.",
        "You know, it's interesting you should say that because I've always wanted a peg leg.",
        "Hell, on a lake this size you're going to have eight, nine deaths in a season. That's just statistical fact.",
        "I'm sorry about Queequeg.",
    ],
    surekill: [
        "Check her right-hand desk drawer. It's all there, the whole story.",
        "I got bad peepers. Legally blind since birth.",
        "Can't you tell your brother to get some lunch or something at least? He's practically right outside the door.",
        "He watched her every day, wherever she was. A man who could look at anything in the world and he chooses her.",
        "These holes line up perfectly with the three victims behind there, which tells me that the shooter was standing right here behind this wall.",
        "You know, I gotta get into the bug business. I had no idea you could make so much dough killing things that creep around behind walls.",
    ],
    millennium: [
        "The world didn't end.",
        "I'm retired. I think you can tell by the circumstances that I'm trying to put my life back together.",
        "I am the resurrection and the life.",
        "The body of an FBI agent gets disinterred only to climb out on its own and disappear into the Yuletide night.",
        "I don't think it was grave robbery per se. It was necromancy.",
        "I'll get well and I'll jump through whatever hoops they want, but I will not mention the Millennium Group again.",
    ],
    redux: [
        "I've never underestimated Mulder. I still don't.",
        "If my work with Agent Mulder has tested the foundation of my beliefs, science has been and continues to be my guiding light.",
        "Why are you sitting in my bedroom in the dark?",
        "This man, Ostelhoff, was set up in the apartment directly above mine.",
        "I paid the price, Agent Mulder. When my son came back sick from the Gulf War, that was my retribution.",
        "It's deionized water. It's nothing more than that.",
    ],
    medusa: [
        "I was sent a scribbled note and a saltwater sample for analysis.",
        "I see signs of the same tissue degradation we saw in the other victims. We may have a contagion here after all.",
        "You've got capable people with you, Agent Doggett. What I need down there are eyes and ears.",
        "Yep, we're on the tunnel floor. Hope you got yourself a nice clear picture, Agent Scully.",
        "Sweat. Perspiration is a conductor for calcium ions promoting a chemical-electrical reaction.",
        "One of the main arteries of the Boston Public Transport System has been closed for exactly 11 hours and four minutes.",
    ],
    theBlessingWay: [
        "With all due respect, sir, I think you overestimate your position in the chain of command.",
        "We predict the future and the best way to predict the future is to invent it.",
        "He was a good friend. A redwood among sprouts.",
        "I know what you may have heard from the F.B.I. but I have a very strong feeling that your son is going to be found.",
        "The F.B.I. man was weak from his journey and did not wish to speak but like a rising sun, I sensed in him a rebirth.",
        "Is this tape what Agent Mulder died for?",
    ],
    dreamlandII: [
        "Once upon a time, there was a guy with the improbable name of Fox Mulder.",
        "I didn't intend for it to crash, Agent Mulder. I just meant to disable the stealth module so you could see it.",
        "Hey, Grandma Top Gun, will you shut the hell up?",
        "'Baby' me and you'll be peeing through a catheter.",
        "You know, Morris, most men - when they have a mid-life crisis - they go out and buy themselves a sports car. They don't run around calling themselves Fox.",
        "The warp that started this is snapping back like a rubber band.",
    ],
    provenance: [
        "Well, if this is an X-File then why don't you ask somebody who is working on the X-Files?",
        "They were found during an illegal border crossing in North Dakota. Canada to the U.S.",
        "Answers about my son. I know how that sounds. I have no one else to say it to.",
        "That's why you have 40 agents combing the Canadian border for a dead man that ain't dead.",
        "Mom, I can't take this on faith. I need to know. I need to know if it's really God I have to thank.",
        "Agent Reyes, I need you to take my mom and the baby. Can you do that, Monica?",
    ],
    tesoDosBichos: [
        "Partial rat body part.",
        "They should have left it buried. None of this would have happened.",
        "They're saying the body of a female shaman is sacred to the tribe. They won't allow us to disturb her.",
        "The Ecuadorean government claimed he was carried off in some kind of wild animal attack.",
        "There's water on every seat. It's more like every toilet overflowed. Now, why would that happen?",
        "You think Bilac's innocent? That the victim wasn't even murdered at all? That he was devoured by a mythological jaguar spirit?",
    ],
    chinga: [
        "He worked very hard to build that little house for her and when that daughter came, you'd need a mop to wipe that smile off his face.",
        "Well, you know, short of looking for a lady wearing a pointy hat riding a broomstick, I think you pretty much got it covered there.",
        "Dave, the butcher... I think he's dead.",
        "I want to play.",
        "Mulder, I'm on vacation. The weather is clear. I'm looking forward to hitting the road and breathing in some of this fine New England air.",
        "You didn't find a talking doll, did you, Scully?",
    ],
    unruhe: [
        "What if the kidnapper was working construction at both sites?",
        "Apparently in German it means trouble or strife.",
        "It's something about his legs. They're unusually long. They're out of proportion.",
        "She's been given what's called a transorbital lobotomy. It used to be known as an icepick lobotomy.",
        "She's safe from the howlers. She's all right now.",
        "I think he came in here and he looked at her through the window, this close. Close enough to affect the film in that camera.",
    ],
    killSwitch: [
        "He invented the internet. Well, he didn't quite invent it, but he's a Silicon Valley folk hero.",
        "Now they're power brokers and billionaires. Back then they were just inspired nerds.",
        "David missing. Fear the worst. The hunted has become the hunter.",
        "Invisigoth. You want my address? It's T-O-A-S-T.",
        "Donald Gelman was trying to create a sentient AI, a program with its own consciousness. He succeeded, didn't he?",
        "Are these the brain donors that nearly got us incinerated?",
        "Imagine being mingled so completely with another, you no longer need your physical self - you're one.",
        "Oh my god. What did you do with my arm? What did they do with my arm?!",
    ],
    anasazi: [
        "Gentlemen, that was the phone call I never wanted to get.",
        "You have my files and you have my gun. Don't ask me for my trust.",
        "I think it's just encrypted and I think I recognize it. It looks like Navajo.",
        "You're your own man, Bill. You always have been. But I strongly encourage you in that event to deny everything.",
        "You shot me.",
        "I'm in a boxcar buried inside a quarry. There are bodies everywhere.",
    ],
    theUnnatural: [
        "I have seen the life on this planet, Scully, and that is exactly why I am looking elsewhere.",
        "Arthur Dales is my brother. My name also happens to be Arthur Dales. It's the same name, different guy.",
        "I'm fine playing here in the cactus league. It's nice and quiet.",
        "I didn't know the unnecessary could feel so good. You know, the game was meaningless but it seemed to mean everything to me. It was useless, but perfect.",
        "This little fellow goes by the name of Pete Rosebud. If you keep pumping coffee money into him he'll tell you a story about baseball and aliens and bounty hunters.",
        "You've never hit a baseball, have you, Scully?",
    ],
    release: [
        "The killer only meant to stab Agent Doggett's victim once. The other wounds were out of anger because he missed.",
        "Is that part of the training here, cadet - smelling body parts?",
        "Cadet Hayes's real name is Stuart Mimms of Mendota, Minnesota. Last known residence the Dakota County Psychiatric Facility.",
        "Seven-year-old boy rides his bike around the block. His mom's on the porch counting his laps. He waves to her every time he goes by and after six laps, he doesn't come back around.",
        "I studied the photos of your son's death. They called to me. I don't know why, but it was a message, and I listened.",
        "He thinks he failed Luke. In his mind, he can never do enough, never suffer enough for what happened.",
    ],
    conduit: [
        "This is the essence of science - you ask an impertinent question and you're on your way to a pertinent answer.",
        "You should ride with us sometime. You might see somethings that'll change your mind, get a killer sunburn in the middle of the night.",
        "Four of the nine girls in the troop claim to have seen something; five if you include the den mother.",
        "Because the lizard baby wasn't born anywhere near Lake Okobogee.",
        "Well, it wouldn't be the first time Ruby Morris ran away from home.",
        "Something was out here, Scully. Something hot enough to turn sand into glass, to singe those trees, and to blister the roof of that camper.",
    ],
    alone: [
        "Aim at the sound of my voice. Aim at me!",
        "It's burned my eyes with something. My vision's all blurry.",
        "I'm betting that Agent Doggett can take care of himself. He's a big boy. You gotta worry about the little boy. Or little girl?",
        "When you went to Antarctica to save Agent Scully from being taken by that spaceship and you ran out of gas in your Sno-Cat, how did you get back?",
        "I don't know how to tell you this, Agent Harrison. You're being used. Deputy Director Kersh is no friend of the X-Files.",
        "It's a medallion commemorating the Apollo 11 space flight. I'd like to give this to you, Agent Doggett.",
    ],
    joseChungs: [
        "Mulder, you're nuts!",
        "Oh. He was here just a few minutes ago. I guess he's still AWOL.",
        "This is not happening. This is NOT happening.",
        "I don't like what he's doing. It's like he's inside my mind, like he's stealing my memories.",
        "Hey, I just got a call from some crazy blankety-blank claiming he found a real live dead alien body.",
        "No other object has been misidentified as a flying saucer more often than the planet Venus.",
    ],
    colony: [
        "Oh, I changed it to 'trust everyone.' I didn't tell you?",
        "I got these a week ago. I stepped in something last night that ate clean through the sole.",
        "Special Agent Barrett Weiss out of the Syracuse office. His body was found stuffed in the trunk of a car in front of a house that you sent him to.",
        "All worked in abortion clinics. They died in separate arson fires.",
        "If you keep him in there you're going to kill him! The cold is the only thing that's keeping him alive.",
        "The program went by the codename 'Gregor,' the name given to every clone.",
    ],
    deepThroat: [
        "Oh man, did we tell him about the landmines and junk?",
        "Let's just say this case has a distinct smell to it - a certain paranormal bouquet.",
        "This guy Budahas received a presidential commendation. He's never washed out of anything in his life.",
        "Mulder, you could have shown that kid a picture of a flying hamburger and he would have told you that's exactly what he saw.",
        "Scully, wake up. You've gotta see this.",
        "Mr. Mulder, they've been here for a long, long time.",
    ],
    monday: [
        "What do you think I'll do then?",
        "Free will. With every choice, you change your fate.",
        "I know I'm not supposed to have a waterbed. I don't know what to tell you. I think it was a gift.",
        "You have control over everything that happens here. You do. And it doesn't have to end this way.",
        "Don't you see? We're all in hell. I'm the only one who knows it.",
        "He's got a bomb. He's got a bomb. He's got a bomb.",
    ],
    theWalk: [
        "Sometimes the only sane response to an insane world is insanity.",
        "I never see him clearly, but he looks like a soldier, always standing at attention.",
        "Your time has come, killer.",
        "I talked with security personnel. Other than Captain Draper, they saw no one enter the pool last night and no one leave.",
        "You owe me, Roach. I wouldn't be in this damn chair if it wasn't for you.",
        "What you're saying is this man, Rappo, is leaving his body and floating around town killing people?",
    ],
    allThings: [
        "Time passes in moments.",
        "We're always running. We're always chasing the next big thing. Why don't you ever just stay still?",
        "Then, in 1997, even more complex formations occurred... and I'm not wearing pants right now.",
        "Accidents - or near accidents - often remind us that we need to keep our mind open to the lessons it gives.",
        "What if there was only one choice and all the other ones were wrong? And there were signs along the way to pay attention to?",
        "Maggie, to be honest, I left so that there wouldn't be hell in your lives.",
    ],
    shadows: [
        "I would never lie. I willfully participated in a campaign of misinformation.",
        "Somatic death occurred sometime over 6 hours ago. Their body temperatures have yet to drop below 98.3 degrees.",
        "I'm fine except I have a waiting-in-line-at-the-DMV-sized headache.",
        "He was closer to me than my father. I told him that. I still feel his presence. Sometimes I even smell his aftershave.",
        "The filaments are heated due to massive levels of electrostatic charge. Just like the bodies at the morgue.",
        "Her actions observed during surveillance indicate a strong relationship between Lauren Kyte and her employer, the late Howard Graves.",
    ],
    fallenAngel: [
        "So this must be the enigmatic Agent Scully.",
        "Negative. What she tracked was a meteor. Its aberrant movement was obviously due to instrument malfunction.",
        "I think you knocked out a filling.",
        "Then what can I say? How can I disprove lies that are stamped with an official seal?",
        "There weren't train tracks anywhere near that site, so how could it have been a derailed container?",
        "Oh, let me introduce myself. My name is Max Fenig. I'm with the National Investigative Committee of Arial Phenomenon - NICAP.",
    ],
    firewalker: [
        "Mulder, I appreciate your concern but I'm ready. I want to work.",
        "There's been a lot of seismic activity lately on the northeast rift zone.",
        "Whatever it was disabled the camera. The temperature readings are upwards of 130 degrees at this depth.",
        "A silicon-based lifeform in the deep biosphere has been one of the Holy Grails of modern science and maybe Trepkos found it.",
        "Unless these spores are ingested or inhaled by the host organism immediately upon their release, they become harmless.",
        "What's the robot doing in the volcano?",
    ],
    aubrey: [
        "A woman senses these things.",
        "Now I know why my mother only had one child. She told me about the nausea but not about the nightmares.",
        "I've often felt that dreams are answers to questions we haven't yet figured out how to ask.",
        "Now, are you about finished with me, little sister?",
        "I remember how the light from the window bounced off the razor. It had an ivory handle.",
        "He would carve the word 'SISTER' on their chests and paint it on the wall with their blood.",
    ],
    unusualSuspects: [
        "They're here! They're here!",
        "Your kung fu is the best.",
        "If you want to watch Matlock with Andy Griffith all blue and squiggly, go right ahead and buy from this guy. But if you want quality bootleg cable, you talk to me.",
        "I was named after JFK. Before the assassination, my parents were going to call me Bertram.",
        "It's an aerosolized gas which in small doses caused anxiety paranoia in its subject.",
        "Can you look up Susanne Modeski? That's my daughter.",
    ],
    bornAgain: [
        "Michelle's nanny. The latest, that is. She's already gone through four this year and it's only April.",
        "Last summer we had to cover our pool. Jim was trying to teach her how to swim, but every time that Michelle would get close to the water she would just start to scream.",
        "Japanese paper folding. Where'd she learn how to do that?",
        "Each time a new doll, anatomically intact, and each time she dismembers and disfigures it in exactly the same way.",
        "Charlie Morris was drowned in his own tropical fish tank and that's the last thing he saw before he died.",
        "Do me a favor, Mulder. Let me say it. Reincarnation.",
    ],
    clydeBruckman: [
        "No, no, just more insight into his character, which I know you hate.",
        "His leads are so vague as to be practically useless yet easily interpreted to be correct after the fact.",
        'What was it about her life? Was it one specific moment where she suddenly said, "I know - dolls."',
        "Would you like a dog? He's paper-trained and well-behaved, regardless of his actions last night, which you can't really blame him for.",
        "Like our lives, this is a mere formality to let you know I know that you know. Can't wait til our first meeting when I kill you.",
        "How can I see the future if it didn't already exist?",
    ],
    nothingImportantII: [
        "I was told to leave this alone. You and Mulder asked me to leave it be, to let Mulder disappear.",
        "You're taking on the entire FBI here, Monica. This witch hunt isn't going to expose anything but you unless you distance yourself from John Doggett.",
        "If this is true - about this ship and this lab - there may be answers there I can't risk not knowing.",
        "Knowle Rohrer told me that your pregnancy was part of a government cloning experiment to create a super soldier.",
        "If you want to deliver something to everyone's home in America, what better way than through what's most taken for granted - water.",
        "I'm a bioengineered combat unit. I have no weaknesses - I don't sleep, I can breathe under water.",
    ],
    existence: [
        "Looks kind of like a vertebra, only metallic.",
        "I feel these energies about people and stuff and places. I found it works best to go with my instincts.",
        "We just crossed into Georgia. Long way from home, Agent Scully.",
        "Now all we need is some mood music, like whale song.",
        "This is a video grab from the DC morgue. It's Billy Miles.",
        "William - after your father.",
    ],
    pineBluffVariant: [
        "Not even close.",
        "I believe in your ideals and your goals, but the only reason I tolerate your methods is because the government's are worse.",
        'Could I get some fresh popcorn, please? The sign says "Fresh Popcorn."',
        "I've given you information. My partner is seriously suspicious. If that's not enough for you, that's all I've got.",
        "If you touch me again, you'd better kill me!",
        "The bank heist was a decoy... to contaminate the money you didn't take.",
    ],
    patientX: [
        "Well, look who's answering the bat-phone.",
        "I'm just trying to save myself any extra humiliation. I'd like to build a reputation here, not be given one.",
        "They've told me that I am an apostle, here to spread the word of the dawning of a new age of supernatural enlightenment.",
        "I guess I'm done here. You seem to have invalidated your own work. Have a nice life.",
        "Mulder, why are you tiptoeing around the obvious fact here? I mean, this is Skyland Mountain.",
        "Cassandra Spender is living proof that the truth I've so boldly sought for the last five years is the truth of a madman.",
    ],
    dieHandDieVerletzt: [
        "I guess their parachutes didn't open.",
        "If he wants to be young, I have no problems with Grease or Annie",
        "The FBI recently concluded a seven year study and found little or no evidence of the existence of occult conspiracies.",
        "Even the Devil can quote scripture to fit his needs.",
        "For your final examination, one-half of your grade will be based on your diagram of a dissected pig embryo.",
        "When I questioned the other staff members about Mrs. Paddock's background, no one can even recall hiring her.",
    ],
    vienen: [
        "You can find a conspiracy at a church picnic.",
        "I'm not privy to this work. I'd have thought this body would have been on its way back to Mexico.",
        "I think he wants us to jump.",
        "I don't speak very good Spanish, Diego. Tu eres Diego Garza?",
        "Answer the phone, Agent Doggett. You're in charge here now.",
        "You need to tell Deputy Director Kersh that he has to order a controlled evacuation of that rig right now.",
    ],
    littleGreenMen: [
        "Whenever he's away I feed his fish.",
        "Seeing is not enough. I should have something to hold onto, some solid evidence. I learned that from you.",
        "Don't touch that red button. No-ho on the rojo.",
        "My God, Scully. It's as if he's been frightened to death.",
        "Before, I could only trust myself. Now I can only trust you.",
        "The X-Files have been shut down. They closed our eyes. Our voices have been silenced.",
    ],
    fearfulSymmetry: [
        "He has a philosophical issue with having his image bounced off a satellite.",
        "The security monitors don't have a recording of anything either - just a giant implosion of glass like some kind of giant shock wave.",
        "I saw David Copperfield make the Statue of Liberty disappear once.",
        "'Light afraid.' Literally, she's afraid of the light.",
        "Those are tie-downs. They're meant to restrict the animal's movement. I disallowed their use when I came to work here.",
        "The W.A.O. believes only tragedy results from keeping animals in captivity.",
    ],
    invocation: [
        "He just didn't recognize you, that's all. He's a nice dog, Sparky. He just doesn't realize that you're part of the family.",
        "These words - anomalous, supernatural, paranormal - they purport to explain something by not explaining it. It's lazy.",
        "Agent Scully, don't ask me to believe that this is some kind of justice from beyond the grave.",
        "We tried everything to find that boy and who abducted him. Went so far as to bring in a police psychic ten years back. Came up with that very same symbol.",
        "And then what? What am I left with? A kid who stabs knives into his brother's mattress?",
        "I took his prints - it's him. Boy should be a teenager by now, but look at him.",
    ],
    oubliette: [
        "Not everything I do, say, think, and feel goes back to my sister.",
        "Nobody's going to spoil us.",
        "Who could do such a thing? Who could take somebody who wasn't theirs?",
        "Whether she knew it or not, she was repeating the exact words spoken by Amy's abductor at the exact same time 20 miles across town.",
        "You can actually feel what she's going through, can't you?",
        "He hasn't touched her. Not yet. He wants to, but he can't. That's why he takes the pictures.",
    ],
    duaneBarry: [
        "Krycek, have you got your notepad? Grande, two percent cappuccino with vanilla.",
        "No, that man is afraid and the only way you're going to win his trust is by trying to understand what he's afraid of.",
        "When you let the women leave, you left the door unlocked. Go lock the door.",
        "They're not taking me again. You got it? They can take somebody else!",
        "In the x-rays, the surgeon found several pieces of metal. In his gums, in his sinus cavity, and one in the abdomen.",
        "The guy escaped a mental institution. He's got four people at gunpoint in an office building - claims he's being controlled by aliens.",
    ],
    leonardBetts: [
        "I'm sorry, but you've got something I need.",
        "Mulder, I think I'm going to need your help. Your arms are longer.",
        "I know you're up to your ass in alligators but it sounds like you patient may be in anaphylactic shock.",
        "I always told him he should have been an oncologist or something.",
        "I mean, you're not suggesting that a headless body kicked his way out of a latched morgue freezer, are you?",
        "He was decapitated when his ambulance crashed.",
    ],
    sunshineDays: [
        "Smile. All of Southern California's watching us scratch our heads in confusion.",
        "And there's no end to the harm he could cause if he goes off the deep end, which isn't too long a walk for this guy in case you haven't noticed.",
        "How else do you explain it? A guy falls from a plane, or a... I don't know, maybe a helicopter.",
        "Cousin Oliver. Who the hell's that?",
        "He was the Mozart of psychokinesis.",
        "That's the Brady Bunch house. That's THE house.",
    ],
    neverAgain: [
        "Why don't I have a desk?",
        "Say it to my face. Right to my face. Now that I'm in front of you, call me a loser to my face!",
        "'Refusing an assignment?' It makes it sound like you're my superior.",
        "This girl is a real doll, but beauty is only skin deep, baby. I go all the way to the bone.",
        "The color - the red on the lips. It's extraordinary.",
        "We may be subject to hallucinogenic ergotism - aural, visual hallucinations; dangerous and unlikely behavior.",
    ],
    syzygy: [
        "Sure. Fine. Whatever.",
        "I know how much you like snapping on the latex.",
        "Hate him, wouldn't wanna date him!",
        "I remember me and Boom back at kindergarten. He was, I dunno, like a brother or something.",
        "I am not going to be humiliated by you, in front of you, or by having to bring a teenage girl in - on her birthday of all days - to identify the bones of her dead dog, Mr. Tippy!",
        "You'd have a Jupiter-Uranus opposition, forming what's called a grand square, where all the planets align into a cross. All the energy of the cosmos would be focused on you.",
    ],
    detour: [
        "She was in the lead and presumably the strongest. They take the strongest first.",
        "The weight distribution is all wrong. People walk heel to toe. Whatever this thing is, it walks on the ball of its foot.",
        "I'll be back soon and we can build a tower of furniture.",
        "Ponce de León came here 450 years ago looking for the Fountain of Youth.",
        "Go, team. 20 more bodies and we'll win the Honey Baked Ham.",
        "Joy to the world. All the boys and girls. Joy to the fishes in the deep blue sea. Joy to you and me.",
    ],
    jumpTheShark: [
        "Nobody knows about that door. That's our secret door.",
        "I told him the Air Force lost a flying saucer in the Bermuda Triangle and that I could recover it for him. I just wanted to cruise the Bahamas.",
        "From their cramped basement office, they pointed fingers at powerful, evil forces.",
        "How much do you trust this Yves Harlow, Lois Runtz, whatever name she's tellin' you?",
        "Sharks have a remarkable immune system. Toxins that would kill nearly any other vertebrate pass right through them.",
        "It means that, like everyone buried here, the world is a better place for them having been in it.",
    ],
    fightClub: [
        "What's so special about you?!",
        "What I'm thinking, Mulder, is how familiar this seems. Playing Watson to your Sherlock. You dangling clues out in front of me one by one.",
        "Plus Mr. Saperstein's going to show me some in-your-face, smack-down moves so I can quit getting my ass kicked so often.",
        "She follows me around trying to ruin my life. I'm not going to let her ruin it this time.",
        "You move a lot and there's also your employment history - 17 jobs in 17 states in the past three years?",
        "I got sucked into a storm drain. The more pressing question is what the hell happened to Betty Templeton and Lulu Pfeiffer?",
    ],
    ebe: [
        "That's why we like you, Mulder. Your ideas are weirder than ours.",
        "I think it's remotely plausible that someone might think you're hot.",
        "Mulder, the truth is out there but so are lies.",
        'They publish a magazine called “The Lone Gunman.” Some of their information is first-rate - covert actions, classified weapons. Some of their ideas are downright spooky.',
        "I have the distinction of being one of three men to have exterminated such a creature.",
        "We go down to investigate a truck driver's encounter with a UFO and the next thing, we're discovering electronic surveillance equipment.",
    ],
    theTruth: [
        "Come here, you big, bald, beautiful man.",
        "I want to believe that the dead are not lost to us.",
        "I'm a guilty man. I failed in every respect. I deserve the harshest punishment for my crime.",
        "Thirty government workers are ready to testify they witnessed Mulder push a military officer to his death.",
        "My name is Dana Katherine Scully. I was assigned nine years ago to the X-Files to spy on Agent Mulder, whose methods the FBI distrusted.",
        "December 22, the year 2012. The date of the final alien invasion.",
    ],
    apocrypha: [
        "I though they were going to kill us both, and then there was this bright flash. That's all I remember.",
        "Scully, if you can't keep your head it's all right to step away.",
        "I think the dead are speaking to us Mulder, demanding justice. Maybe that man was right. Maybe we bury the dead alive.",
        "Looked like some hot-head drew a gun in a coffee shop. Skinner happened to be in the way.",
        "This just wasn't ordinary diesel oil. I think it's a medium - a medium being used by some kind of alien creature that uses it to body jump.",
        "You shot my sister!",
    ],
    freshBones: [
        "He who does evil, evil he will see.",
        "Poor kid. He died six weeks ago in that riot.",
        "I go there for the frogs. You find the best frogs at the cemetery.",
        "This is the tree that stopped Private McAlpin's car. State police reported there's graffiti on the bark.",
        "In 1982, a Harvard ethnobotanist named Wade Davis did extensive field research in Haiti on the zombification phenomenon.",
        "One of the boys told me what they found at the accident and he said it was some kind of voodoo curse.",
    ],
    fire: [
        "Ten to one you can't dance to it.",
        "You guys ever smoked a cigarette?",
        "Oh, come on, don't tell me you left your sense of humor in Oxford ten years ago?",
        "For years I had nightmares about being trapped in a burning building.",
        "It occurred to me that we're going to a lot of trouble to find a description of a man who, in all likelihood, has been burnt to a crisp.",
        "This one likes to burn his victims alive. Can't figure out how he does it either.",
    ],
    theBeginning: [
        "I don't care what patronage got you this job. I put my life in here.",
        "You're thinking you could've destroyed me, too, and if I don't find this creature you will.",
        "He must have accidentally injected himself while he was working on the virus. It took just 12 hours for the creature to gestate.",
        "I hope you know what you're doing, Diana. I hope you know whose errand you're running.",
        "You're asking us to accept this report of a spaceship buried under polar ice and your death-defying escape from it?",
        "Using a process that restores moisture to the documents and by a reassembling of fragments, I should be able to recover a large percentage of the case material that was destroyed in my office fire several months ago",
    ],
    oneSon: [
        "Scully, you're making this personal.",
        "No one is sick or infected here. I mean, I assume that based on you walking in here dressed to the nines offering apologies masquerading as explanations.",
        "You can never save our son. He knows what you've done to me.",
        "If she is, and the aliens learn a hybrid exists, colonization of the planet will begin.",
        "You gave them your children! You gave them your wife! You sent them away like they were things.",
        "Your mother was right. I came here hoping otherwise, hoping that my son might live to honor me like Bill Mulder's son.",
    ],
    shapes: [
        "The very first X-file, initiated by J. Edgar Hoover himself in 1946.",
        "All I know is tomorrow, day after, you're gonna leave, but I have to stay here. I've gotta answer to these people.",
        "I don't want your respects. I want your heart to grow cold. I want you to feel what I'm feeling.",
        "It was dark, we heard a growl, and we went out there to protect the cattle.",
        "Is that why you wouldn't allow an autopsy on Joe Goodensnake's body? You thought it would all end when he was cremated?",
        "We're looking for anything that can create human tracks in one step and animal tracks in the next.",
    ],
    wetwired: [
        "You got a pass to come in here and eat these people's food and watch their TV?",
        "She looked out the window and claims she saw her husband in the hammock with a blonde.",
        "No, I'm going to watch the rest of these tapes. Just out of curiosity.",
        "It's not the truth, Mom. He's lied to me from the beginning.",
        "This subliminal signal - could color be a factor in it? I'm red-green color blind.",
        "Mulder, I share your concern for Agent Scully, but the fact remains she fired four rounds at you and an unarmed civilian last night.",
    ],
    terma: [
        "Don't you cry for me, dear. I'm tired of the pain. You're my angel of mercy.",
        "I was a geologist - quite well known, actually - but now I am just a test subject.",
        "A horse stepped on her throat in a riding accident in Virginia.",
        "Go back to the refinery. See if you can find anybody who knows anything about that truck.",
        "I hear now he's escaped. Wake the Russian bear and it may find we've stolen its honey.",
        "No arm, no test.",
    ],
    sleepless: [
        "Listen, I appreciate the show and tell, and I don't want you to take this personally, but I work alone.",
        "It's almost as if his body believed that it was burning.",
        "Reassigning them to other areas seems to have only strengthened their determination. Scully's a problem - a much larger problem than you described.",
        "The truth is still out there, but it's never been more dangerous. The man we both knew paid for that information with his life, a sacrifice I'm not willing to make.",
        "Dr. Grissom's alpha wave analysis defined the standard. He revolutionized the way we think about sleep.",
        "Maybe Cole built a bridge between the waking world and the dream world. A collective unconscious.",
    ],
    roadrunners: [
        "Hey, excuse me. You put something in my tank that killed my engine.",
        "I wanted to apologize. I left you out of this case and that was a mistake on my part. It was almost a fatal mistake.",
        "The sun seems to rise and set on you as far as they're concerned. In fact, they seem to have stranded me here in order to nurse you back to health.",
        "The local coroner wants a consultation on a murder victim - a man who was found beaten to death in the desert.",
        "You're going to become a part of something much, much greater than you are. You're going to be so loved.",
        "This wound in your back... it seems to be a point of entry for a parasitic organism that has taken up residence along your spine.",
    ],
    fEmasculata: [
        "I don't think the guards are watching enough prison movies.",
        "If this gets out prematurely, the panic is going to spread faster than the contagion.",
        "All I can tell you is that there is a flu-like illness spreading among some of the prisoners.",
        "He found one of the men in pain on the bathroom floor with a large boil on his face.",
        "We finance exploration of new species from the rain forest with potential drug applications.",
        "It kills within thirty-six hours. One escaped convict was infected. He's dead now.",
    ],
    paperHearts: [
        "How about this - sink one from there and I'll tell you.",
        "Will you close your eyes and count to twenty out loud, quietly and slowly?",
        "And you're in the wrong house, you stupid son of a bitch!",
        "Alice... Alice in Wonderland. He's the Mad Hatter.",
        "I was selling vacuum cleaners in 1973. I made a sales trip to Martha's Vineyard that year and I sold a vacuum cleaner to your dad.",
        "I always wanted to find those hearts and count them, see if they really added up to thirteen.",
    ],
    zeroSum: [
        "Police said we could put the bathroom back in service, but the truth is no one wants to use it.",
        "Any kind of bee can be lethal provided you get stung by enough of them.",
        "This man in the baseball hat was identified by the officer on duty at the forensics lab as Agent Mulder.",
        "You've been treating these children for bee stings. You've misdiagnosed them.",
        "I suggest you keep your voice down, Mr. Skinner, unless you want your neighbors to know the hours and the company you keep.",
        "I saved her life once before when I had her returned to Agent Mulder. I may save her life again, but you'll never know if you pull the trigger, will you?",
    ],
    fieldTrip: [
        "You tell me I'm not being scientifically rigorous and that I'm off my nut and then in the end, who turns out to be right like 98.9% of the time?",
        "The bodies may have been stripped then skeletonized, possibly by boiling or by the use of some kind of acid solution.",
        "The remains were found in a swampy area. It's some sort of organic material relating to that. It's bog sludge.",
        "It's more of that digestive material. Looks like it's coming up from out of the ground.",
        "We know that the organism extends for at least ten acres, though it's mostly subterranean so it may be far larger.",
        "Mulder, this is not reality. This is a hallucination. It has to be.",
    ],
    scaryMonsters: [
        "I'm back in accounting now. Happily, mind you. No regrets.",
        "Your lack of imagination saved our lives.",
        "You told me there wasn't anything inside that cat.",
        "There's something under the bed. You have to check.",
        "I wanted to get your opinion on an autopsy finding. It's pretty unusual. A woman who stabbed herself sixteen times.",
        "Why would you draw this, Tommy? Why would you imagine such awful things?",
    ],
    herrenvolk: [
        "Everything dies.",
        "Well, now don't you all take the cake? Does your mom ever mix you up?",
        "I've come in receipt of some pictures of your visit to Mrs. Mulder's summer house.",
        "What I'm saying is that I think this protein is a tag - some kind of genetic marker that was applied to me when I was inoculated against smallpox as a child.",
        "My mother is dying. I need to take you to see her right now.",
        "That looks just like my sister, but that's not possible. She's no older than the day she was taken.",
    ],
    improbable: [
        "You know your problem, my friend? It's not the cards. It's playing the hand you were dealt.",
        "Do you believe the universe is knowable as a mathematical calculation of the whole, reducible to a single equation?",
        "It must be stamped on the killer's ring. 6-6-6, the work of the devil.",
        "I'm with the FBI investigating a series of deaths that seem to have a numerological connection.",
        "He kills in threes. Blonde, redhead, brunette. The next victims will be a redhead and a brunette.",
        "Sir, does it look like we're here to play checkers?",
    ],
    three: [
        "Agent Mulder, this is our forensic dentist you requested.",
        "We'll rise up! You can't kill us! You're not one of us!",
        "I'm familiar with porphyria. It's an affliction which causes lesions and blisters when skin is exposed to sunlight, not fourth degree burns.",
        "It's said that one can protect oneself eating bread filled with blood.",
        "He is the Father, I am the Son, and she is the Unholy Spirit.",
        "The wire reported a body was found drained of blood, bite marks on the exterior jugular and median cubital veins. Every mirror in the house was smashed.",
    ],
    johnDoe: [
        "We've got a Marine down here who's been hit by a taxi cab. He's been messed up pretty good. He doesn't remember his own name.",
        "I'll take the bad as long as I remember the good.",
        "Cartel owns the cops in this town. They're going to kill us.",
        "You can't tell me you're happier now because you recall your life. I saw it all.",
        "I'm your partner Monica. Your name is John Doggett. You're an FBI Agent.",
        "Desaparecido. You're one of the disappeared ones.",
    ],
    nisei: [
        "I got tired of losing my gun.",
        "You're afraid to remember, aren't you? It's okay. We were all afraid at first.",
        "According to the magazine ad I answered, it's an alien autopsy. Guaranteed authentic.",
        "The Talapus was a salvage ship out of San Diego. They spent months looking for a Japanese sub that went down in World War II.",
        "They've been taking Betsy since she was in her teens. This is what's going to happen to all of us.",
        "Mulder, don't get on the train.",
    ],
    redrum: [
        "It's the swipe card that they use at the apartment building. For all I know, it's mine.",
        "As I'm sure you'd agree, Mr. Wells' status as a prosecutor makes him a target. The court puts his life in danger by keeping him in custody.",
        'First thing you are supposed to say is: "What nanny-cam?"',
        "This is the day. If I go, I'll get shot. You cannot transfer me.",
        "He's got a tattoo of a spiderweb on his left hand.",
        "I'm moving backward in time. Every morning I wake up, it's the day before.",
    ],
    hungry: [
        "I was here before you turned off the light. Grandfather clause, man.",
        "Diet pills. Yours, right?",
        "My name is Dr. Mindy Rinehart and I'm a licensed mental health counselor with the Lucky Boy Corporation's employee assistance program.",
        "Yeah, 'free fer' Fridays. It's our promotion where you buy one superpatty and get one free.",
        "I think I need help. I have compulsions to eat.",
        "I wouldn't exactly call it surgery. What if this man's brain was eaten?",
    ],
    dpo: [
        "No, man, not the cows again.",
        "We can take any car you want. You know, you just take your pick. I mean, we can take an Accord or a Maxima. Do you like any of those?",
        "Maybe you didn't hear me right. I said it was my game.",
        "Don't worry, Mr. Kiveat. I've seen how they do it on TV.",
        "So what? Are we supposed to charge him with assaulting a cellular phone?",
        "This is the first lightning strike I've ever seen that left behind a footprint.",
    ],
    providence: [
        "Maybe when you're lost you knock at the door with the porch light on.",
        "There'd be soldiers in Vietnam, bodies turned inside out. I'd hold their hands just like you're doing, tell them it was going to be okay.",
        "Your child was a miracle, Dana. I don't doubt that the things I've witnessed are any less miraculous.",
        "John Doggett was seriously injured by the same female shooter as he tried to halt her vehicle.",
        "Before William was kidnapped, Byers was able to tuck a cell phone under the padding of the baby's car seat.",
        "Josepho said God spoke to him of a miracle child - a future savior coveted by forces of good and evil.",
    ],
    roland: [
        "You must like stars.",
        "You've got more shirts than I do. I think this one would look stylin' today. What do you think?",
        "Somebody's going to crack Mach 15 and I want that someone to be us.",
        "Wouldn't your client find it somewhat inconvenient to be thawed out in the future only to discover he had no functional mobility?",
        "How do you suppose he became trapped in the wind tunnel?",
        "I believe in psychic connections and evidence suggests that it's stronger between family members - strongest of all between twin siblings.",
    ],
    squeeze: [
        "Then I can't wait until you fall off and land on your ass.",
        "In our investigations, you may not always agree with me but at least you respect the journey.",
        "Spooky? Do you think I'm spooky?",
        "Is there any way I can get it off my fingers quickly without betraying my cool exterior?",
        "And he should stick out in a crowd with ten inch fingers.",
        "Each victim was found with their liver ripped out, no cutting tools used.",
    ],
    thisIsNotHappening: [
        "Let's just say I don't not believe. As I said, I try to stay open.",
        "They do these plaster castings and the ones they got from the field that night were from 9½ Nikes. You ever hear of an alien in Nikes?",
        "Her name's Monica Reyes. I worked a case with her once.",
        "Inside her cheeks there's tissue damage in a linear pattern. Her chest was cut into and organ tissue in her abdomen's scooped away.",
        "If you're trying to prepare yourself I want you to stop. Nothing says that we're going to stumble over him in some field. Nothing says he won't be fine.",
        "The ships come in, drop the abductees all messed up. I go and get them so I can help them.",
    ],
    humbug: [
        "But I am an F.B.I. agent.",
        "Having not known the deceased personally, I am in no position to perform a proper eulogy. I'm sure he was a nice guy, et cetera, et cetera.",
        "We're exhuming your potato.",
        "He eats anything - live animals, dead animals, rocks, light bulbs, corkscrews, battery cables, cranberries.",
        "It's not a funhouse. It's a tabernacle of terror.",
        "I don't think he knows he's harming anyone. He's merely seeking another brother.",
    ],
    ghostInTheMachine: [
        "So much for the element of surprise. What do you say we take the stairs?",
        "Data travelers, electro wizards, techno anarchists. Anything's possible.",
        "Jerry, you went into my office and you stole my work.",
        "Eurisko is not your company, Brad - not anymore.",
        "You created that machine. Now you tell me how to destroy it.",
        "The central operating system. It runs the building. It regulates everything from energy output to the volume of water in each toilet flush.",
    ],
    revelations: [
        "You must come full circle to find the truth.",
        "Isn't a saint or a holy person just another term for someone who's abnormal?",
        "How is it that you're able to go out on a limb whenever you see a light in the sky, but you're unwilling to accept the possibility of a miracle?",
        "I carved all the animals myself. It's the ark. Do you like it?",
        "Mostly it just makes me afraid - afraid that God is speaking but that no one's listening.",
        "Kevin arrived at school with bleeding wounds on his hands and feet.",
    ],
    theRainKing: [
        "I need a six-pack of beer, a carton of Morley Lights, and a big old bowl of jellybeans. And can you pick out all the green ones 'cause he don't like the green ones.",
        "How can a frog tell a swan that he loves her?",
        "I'm telling you, that cow had my name on it.",
        "It seems to me that the best relationships - the ones that last - are frequently the ones that are rooted in friendship.",
        "We had a fight and he took a drive and a freak hailstorm cost him his leg.",
        "I believe Daryl's causing the drought so he can charge folks for the rain.",
    ],
    elMundoGira: [
        "God curses the man who stands between two brothers.",
        "That may be, but I will tell you with a tremendous degree of certainty this guy is not Erik Estrada.",
        "I think that her body was so ravaged by pesticides that a normally benign fungus may have compromised her whole immune system.",
        "Can you tell me why we're standing out here in the middle of a field looking at a dead goat?",
        "It still doesn't explain the flash of light and the yellow rain.",
        "El Chupacabra. That's what happened to Maria Dorantes.",
    ],
    xCops: [
        "Because the FBI has nothing to hide.",
        "I don't know what it is about a full moon. It's just something about it. People just go off the wall.",
        "You were responding to the same call we were - of a monster prowling the neighborhood.",
        "You don't think it's some guy dressed up like Freddy Krueger, do you?",
        "It seems to spread like a contagion, doesn't it? One person's fear becomes the next.",
        "'What is it?' Mulder, have you noticed that we're on television?",
    ],
    theJerseyDevil: [
        "He's a jerk. He's not a jerk - he's obsessed with his work.",
        "Unlike you, Mulder, I would like to have a life.",
        "You can't fill those casinos, this town disappears like a quarter down the slot.",
        "Found a half-eaten rabbit with what looked like a human cuspid tooth in it.",
        "Haven't there been cases where men have been raised in the wilderness by animals who have no language and hunt like predators?",
        "We got a naked woman, just jumped from a second-story window. Suspect is headed south into the woods on foot.",
    ],
    daemonicus: [
        "Sorry to disappoint you, but this is a course in forensic pathology. Hard science.",
        "Perhaps you feel that chasing ghosts will answer the questions which damn you.",
        "The beginning and the end of our case. The snake eating its own tail. We lost.",
        "This is not about demons. It's not about demonic posession. It's about men.",
        "You can't compete with the long lost Agent Mulder.",
        "Do you believe in the power of the Devil, Mr. Doggett?",
    ],
    smallPotatoes: [
        "I don't imagine you need to be told this, Mulder, but you're not a loser.",
        "The H is silent.",
        "Hey Scully, if you could be somebody else for a day, who would it be?",
        "My friend Sylvia and her idiot prom date had built this campfire that went totally out of control and so we had to ride back on the, um, what do you call it? The pumper truck.",
        "Children born with vestigial tails don't interest you?",
        "I'm just saying hypothetically, if some women wanted to have kids, their husbands weren't capable, and everybody was happy and no one got hurt, well hypothetically, where's the crime?",
    ],
    within: [""],
    theGift: [""],
    reduxII: [""],
    elegy: [""],
    oneBreath: [""],
    gethsemane: [""],
    triangle: [""],
    ice: [""],
    tooms: [""],
};

export const quotesList = [
    { date: '6-6-2023', episodeNumber: 'Season 3, Episode 2', quotes: quotes.paperClip, title: 'Paper Clip'},
    { date: '7-6-2023', episodeNumber: 'Season 4, Episode 5', quotes: quotes.theFieldWhereIDied, title: 'The Field Where I Died'},
    { date: '8-6-2023', episodeNumber: 'Season 6, Episode 15', quotes: quotes.arcadia, title: 'Arcadia'},
    { date: '9-6-2023', episodeNumber: 'Season 4, Episode 17', quotes: quotes.tempusFugit, title: 'Tempus Fugit'},
    { date: '10-6-2023', episodeNumber: 'Season 7, Episode 15', quotes: quotes.enAmi, title: 'En Ami'},
    { date: '11-6-2023', episodeNumber: 'Season 3, Episode 22', quotes: quotes.quagmire, title: 'Quagmire'},
    { date: '12-6-2023', episodeNumber: 'Season 8, Episode 8', quotes: quotes.surekill, title: 'Surekill'},
    { date: '13-6-2023', episodeNumber: 'Season 7, Episode 4', quotes: quotes.millennium, title: 'Millennium'},
    { date: '14-6-2023', episodeNumber: 'Season 5, Episode 1', quotes: quotes.redux, title: 'Redux'},
    { date: '15-6-2023', episodeNumber: 'Season 8, Episode 12', quotes: quotes.medusa, title: 'Medusa'},
    { date: '16-6-2023', episodeNumber: 'Season 3, Episode 1', quotes: quotes.theBlessingWay, title: 'The Blessing Way'},
    { date: '17-6-2023', episodeNumber: 'Season 6, Episode 5', quotes: quotes.dreamlandII, title: 'Dreamland II'},
    { date: '18-6-2023', episodeNumber: 'Season 9, Episode 9', quotes: quotes.provenance, title: 'Provenance'},
    { date: '19-6-2023', episodeNumber: 'Season 3, Episode 18', quotes: quotes.tesoDosBichos, title: 'Teso Dos Bichos'},
    { date: '20-6-2023', episodeNumber: 'Season 5, Episode 10', quotes: quotes.chinga, title: 'Chinga'},
    { date: '21-6-2023', episodeNumber: 'Season 4, Episode 4', quotes: quotes.unruhe, title: 'Unruhe'},
    { date: '22-6-2023', episodeNumber: 'Season 5, Episode 11', quotes: quotes.killSwitch, title: 'Kill Switch'},
    { date: '23-6-2023', episodeNumber: 'Season 2, Episode 25', quotes: quotes.anasazi, title: 'Anasazi'},
    { date: '24-6-2023', episodeNumber: 'Season 6, Episode 19', quotes: quotes.theUnnatural, title: 'The Unnatural'},
    { date: '25-6-2023', episodeNumber: 'Season 9, Episode 17', quotes: quotes.release, title: 'Release'},
    { date: '26-6-2023', episodeNumber: 'Season 1, Episode 4', quotes: quotes.conduit, title: 'Conduit'},
    { date: '27-6-2023', episodeNumber: 'Season 8, Episode 19', quotes: quotes.alone, title: 'Alone'},
    { date: '28-6-2023', episodeNumber: 'Season 3, Episode 20', quotes: quotes.joseChungs, title: "Jose Chung's From Outer Space"},
    { date: '29-6-2023', episodeNumber: 'Season 2, Episode 16', quotes: quotes.colony, title: 'Colony'},
    { date: '30-6-2023', episodeNumber: 'Season 1, Episode 2', quotes: quotes.deepThroat, title: 'Deep Throat'},
    { date: '1-7-2023', episodeNumber: 'Season 6, Episode 14', quotes: quotes.monday, title: 'Monday'},
    { date: '2-7-2023', episodeNumber: 'Season 3, Episode 7', quotes: quotes.theWalk, title: 'The Walk'},
    { date: '3-7-2023', episodeNumber: 'Season 7, Episode 17', quotes: quotes.allThings, title: 'all things'},
    { date: '4-7-2023', episodeNumber: 'Season 1, Episode 6', quotes: quotes.shadows, title: 'Shadows'},
    { date: '5-7-2023', episodeNumber: 'Season 1, Episode 10', quotes: quotes.fallenAngel, title: 'Fallen Angel'},
    { date: '6-7-2023', episodeNumber: 'Season 2, Episode 9', quotes: quotes.firewalker, title: 'Firewalker'},
    { date: '7-7-2023', episodeNumber: 'Season 2, Episode 12', quotes: quotes.aubrey, title: 'Aubrey'},
    { date: '8-7-2023', episodeNumber: 'Season 5, Episode 3', quotes: quotes.unusualSuspects, title: 'Unusual Suspects'},
    { date: '9-7-2023', episodeNumber: 'Season 1, Episode 22', quotes: quotes.bornAgain, title: 'Born Again'},
    { date: '10-7-2023', episodeNumber: 'Season 3, Episode 4', quotes: quotes.clydeBruckman, title: "Clyde Bruckman's Final Repose"},
    { date: '11-7-2023', episodeNumber: 'Season 9, Episode 2', quotes: quotes.nothingImportantII, title: 'Nothing Important Happened Today II'},
    { date: '12-7-2023', episodeNumber: 'Season 8, Episode 21', quotes: quotes.existence, title: 'Existence'},
    { date: '13-7-2023', episodeNumber: 'Season 5, Episode 18', quotes: quotes.pineBluffVariant, title: 'The Pine Bluff Variant'},
    { date: '14-7-2023', episodeNumber: 'Season 5, Episode 13', quotes: quotes.patientX, title: 'Patient X'},
    { date: '15-7-2023', episodeNumber: 'Season 2, Episode 14', quotes: quotes.dieHandDieVerletzt, title: 'Die Hand Die Verletzt'},
    { date: '16-7-2023', episodeNumber: 'Season 8, Episode 18', quotes: quotes.vienen, title: 'Vienen'},
    { date: '17-7-2023', episodeNumber: 'Season 2, Episode 1', quotes: quotes.littleGreenMen, title: 'Little Green Men'},
    { date: '18-7-2023', episodeNumber: 'Season 2, Episode 18', quotes: quotes.fearfulSymmetry, title: 'Fearful Symmetry'},
    { date: '20-7-2023', episodeNumber: 'Season 8, Episode 5', quotes: quotes.invocation, title: 'Invocation'},
    { date: '21-7-2023', episodeNumber: 'Season 3, Episode 8', quotes: quotes.oubliette, title: 'Oubliette'},
    { date: '22-7-2023', episodeNumber: 'Season 2, Episode 5', quotes: quotes.duaneBarry, title: 'Duane Barry'},
    { date: '23-7-2023', episodeNumber: 'Season 4, Episode 12', quotes: quotes.leonardBetts, title: 'Leonard Betts'},
    { date: '24-7-2023', episodeNumber: 'Season 9, Episode 18', quotes: quotes.sunshineDays, title: 'Sunshine Days'},
    { date: '25-7-2023', episodeNumber: 'Season 4, Episode 13', quotes: quotes.neverAgain, title: 'Never Again'},
    { date: '26-7-2023', episodeNumber: 'Season 3, Episode 13', quotes: quotes.syzygy, title: 'Syzygy'},
    { date: '27-7-2023', episodeNumber: 'Season 5, Episode 4', quotes: quotes.detour, title: 'Detour'},
    { date: '28-7-2023', episodeNumber: 'Season 9, Episode 15', quotes: quotes.jumpTheShark, title: 'Jump the Shark'},
    { date: '29-7-2023', episodeNumber: 'Season 7, Episode 20', quotes: quotes.fightClub, title: 'Fight Club'},
    { date: '30-7-2023', episodeNumber: 'Season 1, Episode 17', quotes: quotes.ebe, title: 'E.B.E.'},
    { date: '31-7-2023', episodeNumber: 'Season 9, Episodes 19 & 20', quotes: quotes.theTruth, title: 'The Truth I & II'},
    { date: '1-8-2023', episodeNumber: 'Season 3, Episode 16', quotes: quotes.apocrypha, title: 'Apocrypha'},
    { date: '2-8-2023', episodeNumber: 'Season 2, Episode 15', quotes: quotes.freshBones, title: 'Fresh Bones'},
    { date: '3-8-2023', episodeNumber: 'Season 1, Episode 12', quotes: quotes.fire, title: 'Fire'},
    { date: '4-8-2023', episodeNumber: 'Season 6, Episode 1', quotes: quotes.theBeginning, title: 'The Beginning'},
    { date: '5-8-2023', episodeNumber: 'Season 6, Episode 12', quotes: quotes.oneSon, title: 'One Son'},
    { date: '6-8-2023', episodeNumber: 'Season 1, Episode 19', quotes: quotes.shapes, title: 'Shapes'},
    { date: '7-8-2023', episodeNumber: 'Season 3, Episode 23', quotes: quotes.wetwired, title: 'Wetwired'},
    { date: '8-8-2023', episodeNumber: 'Season 4, Episode 9', quotes: quotes.terma, title: 'Terma'},
    { date: '9-8-2023', episodeNumber: 'Season 2, Episode 4', quotes: quotes.sleepless, title: 'Sleepless'},
    { date: '11-8-2023', episodeNumber: 'Season 8, Episode 4', quotes: quotes.roadrunners, title: 'Roadrunners'},
    { date: '12-8-2023', episodeNumber: 'Season 2, Episode 22', quotes: quotes.fEmasculata, title: 'F. Emasculata'},
    { date: '13-8-2023', episodeNumber: 'Season 4, Episode 10', quotes: quotes.paperHearts, title: 'Paper Hearts'},
    { date: '14-8-2023', episodeNumber: 'Season 4, Episode 21', quotes: quotes.zeroSum, title: 'Zero Sum'},
    { date: '15-8-2023', episodeNumber: 'Season 6, Episode 21', quotes: quotes.fieldTrip, title: 'Field Trip'},
    { date: '16-8-2023', episodeNumber: 'Season 9, Episode 14', quotes: quotes.scaryMonsters, title: 'Scary Monsters'},
    { date: '17-8-2023', episodeNumber: 'Season 4, Episode 1', quotes: quotes.herrenvolk, title: 'Herrenvolk'},
    { date: '18-8-2023', episodeNumber: 'Season 9, Episode 13', quotes: quotes.improbable, title: 'Improbable'},
    { date: '19-8-2023', episodeNumber: 'Season 2, Episode 7', quotes: quotes.three, title: '3'},
    { date: '20-8-2023', episodeNumber: 'Season 9, Episode 7', quotes: quotes.johnDoe, title: 'John Doe'},
    { date: '21-8-2023', episodeNumber: 'Season 3, Episode 9', quotes: quotes.nisei, title: 'Nisei'},
    { date: '22-8-2023', episodeNumber: 'Season 8, Episode 6', quotes: quotes.redrum, title: 'Redrum'},
    { date: '23-8-2023', episodeNumber: 'Season 7, Episode 3', quotes: quotes.hungry, title: 'Hungry'},
    { date: '24-8-2023', episodeNumber: 'Season 3, Episode 3', quotes: quotes.dpo, title: 'D.P.O.'},
    { date: '25-8-2023', episodeNumber: 'Season 9, Episode 10', quotes: quotes.providence, title: 'Providence'},
    { date: '26-8-2023', episodeNumber: 'Season 1, Episode 23', quotes: quotes.roland, title: 'Roland'},
    { date: '27-8-2023', episodeNumber: 'Season 1, Episode 3', quotes: quotes.squeeze, title: 'Squeeze'},
    { date: '28-8-2023', episodeNumber: 'Season 8, Episode 14', quotes: quotes.thisIsNotHappening, title: 'This Is Not Happening'},
    { date: '29-8-2023', episodeNumber: 'Season 2, Episode 20', quotes: quotes.humbug, title: 'Humbug'},
    { date: '30-8-2023', episodeNumber: 'Season 1, Episode 7', quotes: quotes.ghostInTheMachine, title: 'Ghost in the Machine'},
    { date: '31-8-2023', episodeNumber: 'Season 3, Episode 11', quotes: quotes.revelations, title: 'Revelations'},
    { date: '1-9-2023', episodeNumber: 'Season 6, Episode 8', quotes: quotes.theRainKing, title: 'The Rain King'},
    { date: '2-9-2023', episodeNumber: 'Season 4, Episode 11', quotes: quotes.elMundoGira, title: 'El Mundo Gira'},
    { date: '3-9-2023', episodeNumber: 'Season 7, Episode 12', quotes: quotes.xCops, title: 'X-Cops'},
    { date: '6-9-2023', episodeNumber: 'Season 1, Episode 5', quotes: quotes.theJerseyDevil, title: 'The Jersey Devil'},
    { date: '14-9-2023', episodeNumber: 'Season 9, Episode 3', quotes: quotes.daemonicus, title: 'Daemonicus'},
    { date: '15-9-2023', episodeNumber: 'Season 4, Episode 20', quotes: quotes.smallPotatoes, title: 'Small Potatoes'},
    { date: '16-9-2023', episodeNumber: 'Season 8, Episode 1', quotes: quotes.within, title: 'Within'},
    { date: '17-9-2023', episodeNumber: 'Season 8, Episode 11', quotes: quotes.theGift, title: 'The Gift'},
    { date: '18-9-2023', episodeNumber: 'Season 5, Episode 2', quotes: quotes.reduxII, title: 'Redux II'},
    { date: '19-9-2023', episodeNumber: 'Season 4, Episode 22', quotes: quotes.elegy, title: 'Elegy'},
    { date: '20-9-2023', episodeNumber: 'Season 2, Episode 8', quotes: quotes.oneBreath, title: 'One Breath'},
    { date: '21-9-2023', episodeNumber: 'Season 4, Episode 24', quotes: quotes.gethsemane, title: 'Gethsemane'},
    { date: '22-9-2023', episodeNumber: 'Season 6, Episode 3', quotes: quotes.triangle, title: 'Triangle'},
    { date: '23-9-2023', episodeNumber: 'Season 1, Episode 8', quotes: quotes.ice, title: 'Ice'},
    { date: '24-9-2023', episodeNumber: 'Season 1, Episode 21', quotes: quotes.tooms, title: 'Tooms'},
];
