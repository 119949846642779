import React from 'react';
import { episodeTitles } from '../../data/episodeTitles';
import { getDateString } from '../../utils/dates';
import {
  resetQuoteCurrentStreak,
  updateQuotePlayCount,
  updateQuoteWinCount
} from '../../utils/local';
import '../styles/Quotes.css';

interface QuotesGuessContainerProps {
  answerTitle: string;
  currentQuote: number;
  setCurrentQuote: Function;
}

function QuotesGuessContainer(props: QuotesGuessContainerProps): React.ReactElement {
  const [isShowingList, setIsShowingList] = React.useState<boolean>(false);
  const [episodeOptions, setEpisodeOptions] = React.useState<any>([]);
  const [guessValue, setGuessValue] = React.useState<any>('');

  const today = getDateString();
  const guessesRemaining = 7 - props.currentQuote;

  function onTypeGuess(text: string): void {
    setGuessValue(text);
    const textLowerCase = text.toLowerCase();

    const matchingEpisodes = episodeTitles
      .filter(title => title.toLowerCase().indexOf(textLowerCase) > -1);

    setEpisodeOptions(matchingEpisodes);

    if (matchingEpisodes.length > 0 && !isShowingList) {
      setIsShowingList(true);
    } else if (!text && isShowingList) {
      setIsShowingList(false);
    }
  }

  function onSelectOption(episode: string): void {
    setGuessValue(episode);
    setIsShowingList(false);
  }

  function recordLoss(): void {
    localStorage.setItem('quoteResult', 'loss');
    localStorage.setItem('quoteLastDayCompleted', today);

    updateQuotePlayCount();
    resetQuoteCurrentStreak();
    props.setCurrentQuote(7);
  }

  function skipQuote(): void {
    if (props.currentQuote === 1) {
      localStorage.setItem('quoteWrongGuesses', '1');
    } else {
      const wrongGuessCount =
        parseInt(localStorage.getItem('quoteWrongGuesses') || '0', 10);
      const updatedWrongGuessCount = (wrongGuessCount + 1).toString();
      localStorage.setItem('quoteWrongGuesses', updatedWrongGuessCount);
    }

    if(props.currentQuote < 6) {
      const newQuoteNumber = props.currentQuote + 1;
      props.setCurrentQuote(newQuoteNumber);
    } else {
      recordLoss();
    }
  }

  function checkGuess(): void {
    const isCorrectGuess =
      guessValue.toLocaleLowerCase() === props.answerTitle.toLocaleLowerCase();

    if (props.currentQuote === 1) {
      localStorage.setItem('quoteWrongGuesses', '0');
    }
  
    if (isCorrectGuess) {
      localStorage.setItem('quoteResult', 'win');
      localStorage.setItem('quoteLastDayCompleted', today);

      updateQuotePlayCount();
      updateQuoteWinCount();
      props.setCurrentQuote(7);
    } else if (!isCorrectGuess && props.currentQuote < 6) {
      const newQuoteNumber = props.currentQuote + 1;

      setGuessValue('');

      const wrongGuessCount =
        parseInt(localStorage.getItem('quoteWrongGuesses') || '0', 10);
      const updatedWrongGuessCount = (wrongGuessCount + 1).toString();
      localStorage.setItem('quoteWrongGuesses', updatedWrongGuessCount);

      props.setCurrentQuote(newQuoteNumber);
    } else {
      const wrongGuessCount =
        parseInt(localStorage.getItem('quoteWrongGuesses') || '0', 10);
      const updatedWrongGuessCount = (wrongGuessCount + 1).toString();
      localStorage.setItem('quoteWrongGuesses', updatedWrongGuessCount);

      setGuessValue('');
      recordLoss();
    }
  }

  return (
    <div>
        <div className="autocomplete">
            <input
              className="input"
              placeholder="Search for episode title"
              type="text"
              onChange={(e) => onTypeGuess(e.target.value)}
              value={guessValue}
            />
            {isShowingList && (
              <div className="autocomplete-items">
                {episodeOptions.map(
                  (episode: string) => (
                    <div onClick={() => onSelectOption(episode)}>
                      {episode}
                    </div>
                  )
                )}
              </div>
            )}
        </div>
        <span className="submitWrapper">
          <button
            className="button submit"
            disabled={!guessValue.trim()}
            onClick={() => checkGuess()}
          >
            SUBMIT
          </button>
        </span>
        <button
          className="button skip"
          onClick={() => skipQuote()}
        >
          SKIP
        </button>
        <div className="guesses-remaining">
            {`${guessesRemaining} Guesses Remaining`}
        </div>
    </div>
  );
}

export default QuotesGuessContainer;
