import React from 'react';
import { getRandomDateString, POSSIBLE_DATE_STRINGS } from '../../utils/dates';
import { getScreenshotsForDate } from '../../utils/screenshots';
import HighScoreGuessContainer from './HighScoreGuessContainer';
import '../styles/GameContainer.css';
import '../styles/HighScore.css';
import '../Screenshots/Screenshot.css';

interface HighScoreGameProps {
    setIsPlaying: Function;
}

function HighScoreGame(props: HighScoreGameProps) {
    const [possibleDates, setPossibleDates] =
        React.useState<string[]>([...POSSIBLE_DATE_STRINGS]);
    const [activeDate, setActiveDate] = React.useState<string>('');
    const [score, setScore] = React.useState<number>(0);
    const [screenshots, setScreenshots] = React.useState('');
    const [round, setRound] = React.useState(1);

    function getNextScreenshot() {
        const updatedPossibleDates = possibleDates.filter((date) => date !== activeDate);
        const nextDate = getRandomDateString(updatedPossibleDates);

        setPossibleDates(updatedPossibleDates);
        setActiveDate(nextDate);
        getScreenshotsForDate(setScreenshots, nextDate);
    }

    function startNewGame() {
        setPossibleDates([...POSSIBLE_DATE_STRINGS]);
        setActiveDate('');
        setScore(0);
        setScreenshots('');
        setRound(1);
    }

    React.useEffect(() => {
        if (round <= 10) {
            getNextScreenshot();
        }
    }, [round]);

    const srcs = Object.values(screenshots);
    const randomScreenshotSrc = srcs[Math.floor(Math.random() * srcs.length)];

    React.useEffect(() => {
        if (round === 10) {
            const finalScreenshot = { shot1: randomScreenshotSrc };
            //@ts-ignore
            setScreenshots(finalScreenshot);
        }
    }, [randomScreenshotSrc]);

    return (
        <div className="game-container">
            <div className="screenshot">
                <img
                    src={randomScreenshotSrc}
                    alt="screenshot"
                    className="image"
                />
            </div>
            <HighScoreGuessContainer
                highScoreDate={activeDate}
                round={round}
                score={score}
                setRound={setRound}
                setScore={setScore}
            />
            {round > 10 && (
                <>
                <button
                    className="button-global play-again-button"
                    onClick={() => startNewGame()}
                >
                    NEW GAME
                </button>
                <button
                    className="end-button button-global"
                    onClick={() => props.setIsPlaying(false)}
                >
                    BACK
                </button>
            </>
            )}
        </div>
    );
}

export default HighScoreGame;
