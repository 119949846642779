import React from 'react';
import { Typewriter } from 'react-simple-typewriter'
import { quotesList } from '../../data/quotes';
import { getDateString } from '../../utils/dates';
import QuotesGuessContainer from './QuotesGuessContainer';
import QuotesResult from './QuotesResult';
import '../styles/Quotes.css';

function QuotesGame() {
    const isShowingDefaultState = {
        quote1: true,
        quote2: false,
        quote3: false,
        quote4: false,
        quote5: false,
        quote6: false,
    };

    const [isShowing, setIsShowing] = React.useState<any>(isShowingDefaultState);
    const [currentQuote, setCurrentQuote] = React.useState<number>(1);

    React.useEffect(() => {
        const updatedIsShowing = {...isShowing};
        const currentQuoteIndex = `quote${currentQuote}`;
        updatedIsShowing[currentQuoteIndex] = true;
        setIsShowing(updatedIsShowing);
    }, [currentQuote]);

    const today = getDateString();
    const todayQuotesObject = quotesList.find(quotesObj => quotesObj.date === today);
    const quotes = todayQuotesObject?.quotes;

    const quoteLastDayCompleted = localStorage.getItem('quoteLastDayCompleted');
    const hasPlayedToday = quoteLastDayCompleted === today;

    let quoteNumber = 0;
    quotesList.forEach((quote, i) => {
        if (quote.date === today) {
          quoteNumber = i + 1;
        }
      });

    const quoteElements = quotes?.map((quote, i) => {
        // @ts-ignore
        const shouldShowQuote = isShowing[`quote${i + 1}`] || hasPlayedToday;
        const isCurrentQuote = currentQuote === i + 1;

        if (shouldShowQuote && !isCurrentQuote) {
            return (
                <div className='quote'>
                    {quote}
                </div>
            )
        }

        return (
            <div
                className={`${shouldShowQuote ? 'quote' : 'hidden'}`}
            >
                {isCurrentQuote && <Typewriter cursor={true} typeSpeed={40} words={[quote]}/>}
            </div>
        );
    });

    return (
        <>
            <div className="quotes-container">
                <div className="quotes-header">Muldle Quotes #{quoteNumber}</div>
                {quoteElements}
            </div>
            {!hasPlayedToday ? (
                <QuotesGuessContainer
                    answerTitle={todayQuotesObject?.title || ""}
                    setCurrentQuote={setCurrentQuote}
                    currentQuote={currentQuote}
                />
            ) : (
                <QuotesResult
                    answerTitle={todayQuotesObject?.title || ""}
                    answerEpisodeNumber={todayQuotesObject?.episodeNumber || ""}
                    quoteNumber={quoteNumber}
                />
            )}
        </>
    )
}

export default QuotesGame;
