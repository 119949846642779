export const POSSIBLE_DATE_STRINGS = [
  '8-2-2023',
  '9-2-2023',
  '10-2-2023',
  '11-2-2023',
  '12-2-2023',
  '13-2-2023',
  '14-2-2023',
  '15-2-2023',
  '16-2-2023',
  '17-2-2023',
  '18-2-2023',
  '19-2-2023',
  '20-2-2023',
  '21-2-2023',
  '22-2-2023',
  '23-2-2023',
  '24-2-2023',
  '25-2-2023',
  '26-2-2023',
  '27-2-2023',
  '28-2-2023',
  '1-3-2023',
  '2-3-2023',
  '3-3-2023',
  '4-3-2023',
  '5-3-2023',
  '6-3-2023',
  '7-3-2023',
  '8-3-2023',
  '9-3-2023',
  '10-3-2023',
  '11-3-2023',
  '12-3-2023',
  '13-3-2023',
  '14-3-2023',
  '15-3-2023',
  '16-3-2023',
  '17-3-2023',
  '18-3-2023',
  '19-3-2023',
  '20-3-2023',
  '21-3-2023',
  '22-3-2023',
  '23-3-2023',
  '24-3-2023',
  '25-3-2023',
  '26-3-2023',
  '27-3-2023',
  '28-3-2023',
  '29-3-2023',
  '30-3-2023',
  '31-3-2023',
  '1-4-2023',
  '2-4-2023',
  '3-4-2023',
  '4-4-2023',
  '5-4-2023',
  '6-4-2023',
  '7-4-2023',
  '8-4-2023',
  '9-4-2023',
  '10-4-2023',
  '11-4-2023',
  '12-4-2023',
  '13-4-2023',
  '14-4-2023',
  '15-4-2023',
  '16-4-2023',
  '17-4-2023',
  '18-4-2023',
  '19-4-2023',
  '20-4-2023',
  '21-4-2023',
  '22-4-2023',
  '23-4-2023',
  '24-4-2023',
  '25-4-2023',
  '26-4-2023',
  '27-4-2023',
  '28-4-2023',
  '29-4-2023',
  '30-4-2023',
  '1-5-2023',
  '2-5-2023',
  '3-5-2023',
  '4-5-2023',
  '5-5-2023',
  '6-5-2023',
  '7-5-2023',
  '8-5-2023',
  '9-5-2023',
  '10-5-2023',
  '11-5-2023',
  '12-5-2023',
  '13-5-2023',
  '14-5-2023',
  '15-5-2023',
  '16-5-2023',
  '17-5-2023',
  '18-5-2023',
  '19-5-2023',
  '20-5-2023',
  '21-5-2023',
  '22-5-2023',
  '23-5-2023',
  '24-5-2023',
  '25-5-2023',
  '26-5-2023',
  '27-5-2023',
  '28-5-2023',
  '29-5-2023',
  '30-5-2023',
  '31-5-2023',
  '1-6-2023',
  '2-6-2023',
  '3-6-2023',
  '4-6-2023',
  '5-6-2023',
  '6-6-2023',
  '7-6-2023',
  '8-6-2023',
  '9-6-2023',
  '10-6-2023',
  '11-6-2023',
  '12-6-2023',
  '13-6-2023',
  '14-6-2023',
  '15-6-2023',
  '16-6-2023',
  '17-6-2023',
  '18-6-2023',
  '19-6-2023',
  '20-6-2023',
  '21-6-2023',
  '22-6-2023',
  '23-6-2023',
  '24-6-2023',
  '25-6-2023',
  '26-6-2023',
  '27-6-2023',
  '28-6-2023',
  '29-6-2023',
  '30-6-2023',
  '1-7-2023',
  '2-7-2023',
  '3-7-2023',
  '4-7-2023',
  '5-7-2023',
  '6-7-2023',
  '7-7-2023',
  '8-7-2023',
  '9-7-2023',
  '10-7-2023',
  '11-7-2023',
  '12-7-2023',
  '13-7-2023',
  '14-7-2023',
  '15-7-2023',
  '16-7-2023',
  '17-7-2023',
  '18-7-2023',
  '19-7-2023',
  '20-7-2023',
  '21-7-2023',
  '22-7-2023',
  '23-7-2023',
  '24-7-2023',
  '25-7-2023',
  '26-7-2023',
  '27-7-2023',
  '28-7-2023',
  '29-7-2023',
  '30-7-2023',
  '31-7-2023',
  '1-8-2023',
  '2-8-2023',
  '3-8-2023',
  '4-8-2023',
  '5-8-2023',
  '6-8-2023',
  '7-8-2023',
  '8-8-2023',
  '9-8-2023',
  '10-8-2023',
  '11-8-2023',
  '12-8-2023',
  '13-8-2023',
  '14-8-2023',
  '15-8-2023',
  '16-8-2023',
  '17-8-2023',
  '18-8-2023',
  '19-8-2023',
  '20-8-2023',
  '21-8-2023',
  '22-8-2023',
  '23-8-2023',
  '24-8-2023',
  '25-8-2023',
  '26-8-2023',
  '27-8-2023',
  '28-8-2023',
  '29-8-2023',
  '30-8-2023',
  '31-8-2023',
  '1-9-2023',
  '2-9-2023',
  '3-9-2023',
  '6-9-2023',
  '7-9-2023',
  '8-9-2023',
  '14-9-2023',
  '15-9-2023',
  '16-9-2023',
  '17-9-2023',
  '18-9-2023',
  '19-9-2023',
];

export function getDateString(): string {
  const today = new Date();

  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  const dateString = `${day}-${month}-${year}`;

  return dateString;
}

export function getRandomDateString(possibleDates: string[]): string {
  const dateString = possibleDates[Math.floor(Math.random() * possibleDates.length)];
  return dateString;
}
