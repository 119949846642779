import React from 'react';
import './icons.css';

interface CloseIconProps {
  setIsModalOpen: any;
}

function CloseIcon(props: CloseIconProps): React.ReactElement {
  return (
    <svg width="20pt" height="20pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" className="icon" onClick={() => props.setIsModalOpen(false)}>
      <path d="m958.12 904.88c7.0977 7.043 11.094 16.625 11.094 26.625s-3.9961 19.582-11.094 26.625c-7.0781 7.0195-16.656 10.934-26.625 10.875-9.9688 0.058594-19.547-3.8555-26.625-10.875l-304.88-305.25-304.88 305.25c-7.0781 7.0195-16.656 10.934-26.625 10.875-9.9688 0.058594-19.547-3.8555-26.625-10.875-7.0977-7.043-11.094-16.625-11.094-26.625s3.9961-19.582 11.094-26.625l305.25-304.88-305.25-304.88c-9.5117-9.5117-13.227-23.375-9.7461-36.371 3.4805-12.992 13.633-23.145 26.625-26.625 12.996-3.4805 26.859 0.23438 36.371 9.7461l304.88 305.25 304.88-305.25c9.5117-9.5117 23.375-13.227 36.371-9.7461 12.992 3.4805 23.145 13.633 26.625 26.625 3.4805 12.996-0.23438 26.859-9.7461 36.371l-305.25 304.88z"/>
    </svg>
  );
}

export default CloseIcon;
