import React from 'react';
import './styles/Header.css';

function Logo(): React.ReactElement {
  return (
    <span>Muldle</span>
  );
}

export default Logo;
