function compareQuoteStreaks(): void {
  const quoteCurrentStreak = localStorage.getItem('quoteCurrentStreak') || "0";
  const quoteCurrentStreakInt = parseInt(quoteCurrentStreak, 10);

  const quoteMaxStreak = localStorage.getItem('quoteMaxStreak') || "0";
  const quoteMaxStreakInt = parseInt(quoteMaxStreak, 10);

  if (quoteCurrentStreak > quoteMaxStreak) {
    localStorage.setItem('quoteMaxStreak', quoteCurrentStreak);
  }
}

function compareStreaks(): void {
  const currentStreak = localStorage.getItem('currentStreak') || "0";
  const currentStreakInt = parseInt(currentStreak, 10);

  const maxStreak = localStorage.getItem('maxStreak') || "0";
  const maxStreakInt = parseInt(maxStreak, 10);

  if (currentStreakInt > maxStreakInt) {
    localStorage.setItem('maxStreak', currentStreak);
  }
}

export function getStats(view: string): any {
  let playCount = '0';
  let winCount = '0';
  let currentStreak = '0';
  let maxStreak = '0';

  if (view === 'classic') {
    playCount = localStorage.getItem('playCount') || '0';
    winCount = localStorage.getItem('winCount') || '0';
    currentStreak = localStorage.getItem('currentStreak') || '0';
    maxStreak = localStorage.getItem('maxStreak') || '0';
  } else {
    playCount = localStorage.getItem('quotePlayCount') || '0';
    winCount = localStorage.getItem('quoteWinCount') || '0';
    currentStreak = localStorage.getItem('quoteCurrentStreak') || '0';
    maxStreak = localStorage.getItem('quoteMaxStreak') || '0';
  }

  const playCountInt = parseInt(playCount, 10);
  const winCountInt = parseInt(winCount, 10);

  const winPercent =
    playCountInt === 0 ? 0 : ((100 * winCountInt) / playCountInt).toFixed(2);

  return {
    playCount,
    winCount,
    winPercent,
    currentStreak,
    maxStreak,
  };
}

function incrementCurrentStreak(): void {
  const currentStreak = localStorage.getItem('currentStreak') || "0";
  const currentStreakInt = parseInt(currentStreak, 10);
  const updatedCurrentStreak = (currentStreakInt + 1).toString();

  localStorage.setItem('currentStreak', updatedCurrentStreak);
  compareStreaks();
}

function incremenetQuoteCurrentStreak(): void {
  const quoteCurrentStreak = localStorage.getItem('quoteCurrentStreak') || "0";
  const quoteCurrentStreakInt = parseInt(quoteCurrentStreak, 10);
  const updatedQuoteCurrentStreak = (quoteCurrentStreakInt + 1).toString();

  localStorage.setItem('quoteCurrentStreak', updatedQuoteCurrentStreak);
  compareQuoteStreaks();
}

export function resetCurrentStreak(): void {
  localStorage.setItem('currentStreak', '0');
}

export function resetQuoteCurrentStreak(): void {
  localStorage.setItem('quoteCurrentStreak', '0');
}

export function sethsLeaderboardName(name: string): void {
  localStorage.setItem('hsLeaderboardName', name);
}

export function updatePlayCount(): void {
  const playCount = localStorage.getItem('playCount') || "0";
  const playCountInt = parseInt(playCount, 10);
  const updatedPlayCount = (playCountInt + 1).toString();
  localStorage.setItem('playCount', updatedPlayCount);
}

export function updateQuotePlayCount(): void {
  const quotePlayCount = localStorage.getItem('quotePlayCount') || "0";
  const quotePlayCountInt = parseInt(quotePlayCount, 10);
  const updatedQuotePlayCount = (quotePlayCountInt + 1).toString();
  localStorage.setItem('quotePlayCount', updatedQuotePlayCount);
}

export function updateQuoteWinCount(): void {
  const quoteWinCount = localStorage.getItem('quoteWinCount') || "0";
  const quoteWinCountInt = parseInt(quoteWinCount, 10);
  const updatedQuoteWinCount = (quoteWinCountInt + 1).toString();
  localStorage.setItem('quoteWinCount', updatedQuoteWinCount);

  incremenetQuoteCurrentStreak();
}

export function updateWinCount(): void {
  const winCount = localStorage.getItem('winCount') || "0";
  const winCountInt = parseInt(winCount, 10);
  const updatedWinCount = (winCountInt + 1).toString();
  localStorage.setItem('winCount', updatedWinCount);

  incrementCurrentStreak();
}
