import React from 'react';
import './icons.css';

interface StatsIconProps {
  getStats: () => void;
  isIconDisabled: boolean;
}

function StatsIcon(props: StatsIconProps): React.ReactElement {
  return (
    <span title="Stats">
      <svg width="24pt" height="24pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" className={props.isIconDisabled ? "icon disabled" : "icon"} onClick={props.getStats}>
        <path d="m1100.9 183.99v831.99c0 31.996-26.02 58.016-58.016 58.016h-137.82c-31.996 0-58.016-26.02-58.016-58.016l-0.003907-831.99c0-31.984 26.02-58.016 58.016-58.016h137.82c32 0 58.02 26.031 58.02 58.016zm-426.52 75.184h-148.75c-28.961 0-52.543 23.57-52.543 52.543v709.76c0 28.984 23.582 52.543 52.543 52.543h148.78c28.961 0 52.543-23.559 52.543-52.543l-0.003906-709.76c-0.023437-28.984-23.582-52.543-52.566-52.543zm-368.66 388.43h-159.37c-26.059 0-47.242 21.184-47.242 47.242v331.91c0 26.059 21.184 47.242 47.242 47.242h159.37c26.059 0 47.242-21.184 47.242-47.242v-331.91c0-26.031-21.184-47.238-47.242-47.238z" fillRule="evenodd"/>
      </svg>
    </span>
  );
}

export default StatsIcon;
