import React from 'react';
import GuessContainer from '../GuessContainer';
import Result from '../Results/Result';
import Screenshots from '../Screenshots/Screenshots';
import ScreenshotNav from '../Screenshots/ScreenshotNav';
import { getDateString } from '../../utils/dates';
import '../styles/GameContainer.css';

function Classic() {
  const [guessNumber, setGuessNumber] = React.useState<number>(1);
  const [activeScreenshot, setActiveScreenshot] = React.useState<number>(1);

  const today = getDateString();
  const lastDayCompleted = localStorage.getItem('lastDayCompleted');
  const hasPlayedToday = lastDayCompleted === today;

  return (
    <>
      <Screenshots activeScreenshot={activeScreenshot} />
      <ScreenshotNav
        activeScreenshot={activeScreenshot}
        guessNumber={guessNumber}
        hasPlayedToday={hasPlayedToday}
        setActiveScreenshot={setActiveScreenshot}
      />
      {!hasPlayedToday ? (<GuessContainer
        guessNumber={guessNumber}
        setActiveScreenshot={setActiveScreenshot}
        setGuessNumber={setGuessNumber}
      />) : <Result />}
    </>
  );
}

export default Classic;
