import { child, get, getDatabase, ref, update } from 'firebase/database';
import { initializeApp } from 'firebase/app';
import { DB_URL, FIREBASE_API_KEY } from '../keys/keys';

export function getDb(): any {
    const firebaseConfig = {
        apiKey: FIREBASE_API_KEY,
        databaseURL: DB_URL,
    }

    initializeApp(firebaseConfig);
    return getDatabase();
}

export function writeLeaderboardData(user: string, perfectScores: number): void {
    const db = getDb();
    const userData = {};

    //@ts-ignore
    userData[user] = perfectScores;
    update(ref(db, '/leaderboards/'), userData);

}

export function getLeaderboardData(callback: Function): void {
    const db = ref(getDb());
    get(child(db, `leaderboards/`)).then((snapshot) => {
        if (snapshot.exists()) {
            const leaderboardData = snapshot.val();
            callback(leaderboardData);
        }
    }).catch((error) => {
        console.error(error);
    });
}
