import React from 'react';
import '../styles/HighScore.css';

interface ScoreProps {
    guesses: string[];
}

function ScoreIcons(props: ScoreProps) {
    return (
        <div className="guess-emoji-container">
            {props.guesses.map((guess, i) => (
                <span className="guess-emoji" key={`guess-emoji-${i}`}>
                    {guess === 'empty' ? '⚪' : guess === 'correct' ? '✅' : '❌'}
                </span>
            ))}
        </div>
    );
}

export default ScoreIcons;
