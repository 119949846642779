import React from 'react';
import './styles/Modes.css';

interface ModeSelectProps {
  setView: Function;
  view: string;
}

function ModeSelect(props: ModeSelectProps): React.ReactElement {
  return (
    <div className="modes-container">
      <span
        className={`mode-option ${props.view === 'classic' && "active-mode"}`}
        onClick={() => props.setView('classic')}
      >
        Classic
      </span>
      <span
        className={`mode-option ${props.view === 'quotes' && "active-mode"}`}
        onClick={() => props.setView('quotes')}
      >
        Quotes
      </span>
      <span
        className={`mode-option ${props.view === 'highScore' && "active-mode"}`}
        onClick={() => props.setView('highScore')}
      >
        High Score
      </span>
    </div>
  );
}

export default ModeSelect;
